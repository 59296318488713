import React, { useState } from "react";
import { Link } from "react-router-dom";

import SearchInput from "./SearchInput";
import UserMenu from "./UserMenu";

export default function Header() {
  const [menu, setMenu] = useState(false);

  return (
    <header className="header">
      <div className="inner-header">
        <Link to="/" className="header__logo">
          <strong>{process.env.REACT_APP_NAME}</strong>
        </Link>
        <div className="header__search">
          <SearchInput/>
        </div>
        <div className={"header__nav header__nav--modile" + (menu ? " header__menu--open" : "")}>
          <Link className="header__nav-link" to="/" onClick={() => setMenu(false)}>Главная</Link>
          <Link className="header__nav-link" to="/categories" onClick={() => setMenu(false)}>Категории</Link>
          <Link className="header__nav-link" to="/catalog" onClick={() => setMenu(false)}>Каталог</Link>
        </div>
        <div className="header__nav header__nav--reverse">
          <button onClick={() => setMenu(!menu)} className="header__menu-btn">
            <label className={"menu__btn" + (menu ? " header__menu-btn--toggle" : "")}>
              <span></span>
            </label>
          </button>
          <UserMenu />
        </div>
      </div>
      <div className="header__out-search">
        <SearchInput/>
      </div>
    </header>
  );
}