import React, { useState, useEffect } from "react";

import AnalyticsService from "../../api/AnalyticsService";
import { useFetching } from "../../hooks/useFetching";

import { getNoun } from "../../utils/GetNoun";

import LineChart from "../charts/LineChart";
import DoughnutChart from "../charts/DoughnutChart";
import AreaChart from "../charts/AreaChart";

import Block, {BlockHeader} from "../UI/Block/Block";
import Split, { SplitBlock } from "../UI/Split/Split";
import InputList from "../UI/InputList/InputList";
import DropDown, { DropDownContent } from "../UI/DropDown/DropDown";
import SelectMenu from "../UI/SelectMenu/SelectMenu";
import Alert from "../UI/Alert/Alert";
import Modal from "../UI/Modal/Modal";

import VisibilityIcon from "../icons/VisibilityIcon";
import VisibilityLockIcon from "../icons/VisibilityLockIcon";

export default function AnalyticsAdmin() {

  const [requestData, setRequestData] = useState({labels: [], datasets: []});
  const [categoriesData, setCategoriesData] = useState({labels: [], datasets: []});
  const [brandsData, setBrandsData] = useState({labels: [], datasets: []});

  const [search, setSearch] = useState("");
  const [searchedAnalytics, setSearchedAnalytics] = useState([]);

  const [date, setDate] = useState({mindate: null, maxdate: null});
  const [type, setType] = useState({name: "Товар", value: "item"});

  const analyticsTypes = [
    {name: "Товар", value: "item"},
    {name: "Категория", value: "category"},
    {name: "Бренд", value: "brand"},
  ]

  const [modalView, setModalView] = useState(false);
  const [modalViewData, setModalViewData] = useState({labels: [], datasets: []});
  
  const [fetchDataAnalytics] = useFetching(async () => {
    const response = await AnalyticsService.getWeekRequests();
    if (response.data.status) {
      
      const localLabels = [];
      const localViews = [];
      const localUniques = [];
 
      response.data.response.forEach((metric) => {
        localLabels.push(metric.date.substring(0, 10));
        localViews.push(metric.views);
        localUniques.push(metric.unique);
      })

      setRequestData({
        labels: localLabels,
        datasets: [
          {
            label: 'Просмотры',
            data: localViews,
            borderColor: '#629AEE',
            backgroundColor: 'rgba(53, 162, 235, 0.15)',
          },
          {
            label: 'Уникальные',
            data: localUniques,
            borderColor: '#4caf50',
            backgroundColor: 'rgba(76, 175, 79, 0.15)',
          }
        ]
      });
    }
  });

  const [fetchCategoriesAnalytics] = useFetching(async () => {
    const response = await AnalyticsService.getCategoriesPercents();
    if (response.data.status) {

      const localLabels = [];
      const localViews = [];

      response.data.response.forEach((metric) => {
        localLabels.push(metric.name);
        localViews.push(metric.views);
      });

      setCategoriesData({
        labels: localLabels,
        datasets: [
          {
            label: "Проценты",
            data: localViews,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(230, 230, 230, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(150, 150, 150, 1)',
            ],
            borderWidth: 1,
          }
        ]
      });
    }
  });

  const [fetchBrandsAnalytics] = useFetching(async () => {
    const response = await AnalyticsService.getBrandsPercents();
    if (response.data.status) {

      const localLabels = [];
      const localViews = [];

      response.data.response.forEach((metric) => {
        localLabels.push(metric.name);
        localViews.push(metric.views);
      });

      setBrandsData({
        labels: localLabels,
        datasets: [
          {
            label: "Проценты",
            data: localViews,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(230, 230, 230, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(150, 150, 150, 1)',
            ],
            borderWidth: 1,
          }
        ]
      });
    }
  });

  const [fetchAnalytics] = useFetching(async (name, modal=false) => {
    const response = await AnalyticsService.getByQuery(name, type.value, date.mindate, date.maxdate);
    if (response.data.status) {
      if (!modal) {
        let unique = [];
        let uniqueData = [];

        response.data.response.forEach(metric => {
          if (!unique.includes(metric.name)) {
            unique.push(metric.name);
            uniqueData.push(metric);
          }
        });

        setSearchedAnalytics(uniqueData);
      } else {

        let localLabels = response.data.response.map((metric) => metric.meta.date.split("T")[0]);
        let localData = response.data.response.map((metric) => metric.views);

        setModalViewData({
          labels: localLabels,
          datasets: [
            {
              fill: true,
              label: 'Просмотры',
              data: localData,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
          ]
        })
      }
    } else {
      setSearchedAnalytics([]);
      setModalViewData([]);
    }
  });

  function keyHandler(e) {
    if (e.key === "Enter") fetchAnalytics(search);
  }

  function ModalDataView(name) {
    fetchAnalytics(name, true);
    setModalView(true);
  }

  useEffect(() => {
    fetchDataAnalytics();
    fetchCategoriesAnalytics();
    fetchBrandsAnalytics();
  }, [])

  useEffect(() => {
    fetchAnalytics(search);
  }, [type]);

  return (
    <>
      <Block className="admin-table">
        <LineChart title="Статистика просмотров последней недели" labels={requestData.labels} datasets={requestData.datasets} />
        <div className="inner-block admin-table__row" style={{marginTop: "10px"}}>
          <VisibilityIcon fill="style--fill-second-accent"/>
          <span className="admin-table__row-span">Просмотры за сегодня: {requestData.datasets[0]?.data.at(-1)}</span>
        </div>
        <div className="inner-block admin-table__row" style={{marginBottom: "0px"}}>
          <VisibilityLockIcon fill="style--fill-positive"/>
          <span className="admin-table__row-span">Уникальные за сегодня: {requestData.datasets[1]?.data.at(-1)}</span>
        </div>
      </Block>
      <Block>
        <Split>
          <SplitBlock type="fixed" width="50%">
            <DoughnutChart title="Статистика просмотров категорий" labels={categoriesData.labels} datasets={categoriesData.datasets} />
          </SplitBlock>
          <SplitBlock type="fixed" width="50%">
            <DoughnutChart title="Статистика просмотров брендов" labels={brandsData.labels} datasets={brandsData.datasets} />
          </SplitBlock>
        </Split>
      </Block>
      <Block className="search-sort">
        <div className="search-sort__search">
          <InputList onKeyDown={e => keyHandler(e)} onChange={(search) => setSearch(search)} items={[]} placeholder="Имя категории, бренда или предмета"/>
        </div>
        <div className="search-sort__row">
          <div className="search-sort__option">
            <span className="search-sort__span">Тип</span>
            <DropDown>
              <button className="style--main-accent-lite">{type.name.toLocaleLowerCase()}</button>
              <DropDownContent>
                <Block>
                  <SelectMenu value={type.value} onChange={(e) => setType(e)} btns={analyticsTypes}></SelectMenu>
                </Block>
              </DropDownContent>
            </DropDown>
          </div>
          <div className="search-sort__option">
            <span className="search-sort__span">Начальная дата:</span>
            <input className="style--freeze" style={{marginLeft: "10px"}} onChange={(e) => setDate({...date, mindate: e.target.value})} type="date" />
          </div>
          <div className="search-sort__option">
            <span className="search-sort__span">Конечная дата:</span>
            <input className="style--freeze" style={{marginLeft: "10px"}} onChange={(e) => setDate({...date, maxdate: e.target.value})} max={new Date().toISOString().split("T")[0]} type="date" />
          </div>
        </div>
      </Block>
      {
        searchedAnalytics.length === 0
        ? <Alert style={{marginBottom: "100px"}} className="style--alert-main-accent">Используйте поиск для загрузки данных</Alert>
        : <Block className="admin-table" style={{marginBottom: "100px"}}>
            <BlockHeader>
              <strong>Найденные данные</strong>
            </BlockHeader>
            {
              searchedAnalytics.map((item, index) => 
                <div key={index} className="inner-block admin-table__row">
                  <strong>{item.name}</strong>
                  <div className="admin-table__row-btns">
                    {
                      type.value === "item"
                      ? <button className="style--hover-main-accent" onClick={() => ModalDataView(item.name)}>Посмотреть</button>
                      : <span>{item.views + getNoun(item.views, " просмотр", " просмотра", " просмотров")}</span>
                    }
                  </div>
                </div>
              )
            }
          </Block>
      }
      <Modal visible={modalView} setVisible={setModalView}>
        <Block style={{minWidth: "50vw", minHeight: "auto"}}>
          <AreaChart title="Статистика просмотров" labels={modalViewData.labels} datasets={modalViewData.datasets} />
        </Block>
      </Modal>
    </>
  );
}