import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";

import AuthContext from "../contexts/AuthContext";
import MainContext from "../contexts/MainContext";

import Block from "./UI/Block/Block";

export default function Login({setVisible}) {
  const authContext = useContext(AuthContext);
  const mainContext = useContext(MainContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const router = useNavigate();

  function login(e) {
    e.preventDefault()
    authContext.login(username, password).then(status => {
      if (status) {
        router('/admin');
        setPassword("");
        setVisible(false);
      } else {
        mainContext.createNotification({
          title: "Ошибка",
          subtitle: "Неверный логин или пароль",
          style: "style--negative"
        });
      }
    });
  }

  return (        
    <Block>
      <form className="login">
        <div className="login__image">
          <img src={`${process.env.REACT_APP_URL}/images/login.png`} alt="" />
        </div>
        <div className="login__content">
          <strong>Войти в аккаунт</strong>
          <div className="login__content-row">
            <span>Логин или почта</span>
            <input value={username} onChange={e => setUsername(e.target.value)} placeholder="example@tekh.ru" type="text" />
          </div>
          <div className="login__content-row">
            <span>Пароль</span>
            <input value={password} onChange={e => setPassword(e.target.value)} placeholder="********" type="password" autoComplete="true"/>
          </div>
          <Link to="/" className="login__content-recovery">Восстановить пароль</Link>
          <button onClick={e => login(e)} className="style--positive">Войти</button>
          <div className="login__content-row login__content-row--center">
            <span>Нет аккаунта?</span>
            <Link onClick={() => setVisible(false)} to="signup">Регистрация</Link>
          </div>
        </div>
      </form>
    </Block>
  )
}