import React, { useState, useEffect } from "react";

import { usePage } from "../hooks/usePage";
import { useSwitch } from "../hooks/useSwitch";
import { useFetching } from "../hooks/useFetching";

import CatalogService from "../api/CatalogService";

import Split, { SplitBlock } from "../components/UI/Split/Split";
import Block from "../components/UI/Block/Block";
import FixedMenu, { FixedMenuButton } from "../components/UI/FixedMenu/FixedMenu";

import AnalyticsAdmin from "../components/admin/AnalyticsAdmin";
import AuditAdmin from "../components/admin/AuditAdmin";
import CategoriesAdmin from "../components/admin/CategoriesAdmin";
import BrandsAdmin from "../components/admin/BrandsAdmin";
import ShopsAdmin from "../components/admin/ShopsAdmin";
import CreateItemAdmin from "../components/admin/CreateItemAdmin";
import PendingItemsAdmin from "../components/admin/PendingItemsAdmin";
import ControlItemsAdmin from "../components/admin/ControlItemsAdmin";

import AnalyticsIcon from "../components/icons/AnalyticsIcon";
import EventListIcon from "../components/icons/EventListIcon";
import DiamondIcon from "../components/icons/DiamondIcon";
import CategoryIcon from "../components/icons/CategoryIcon";
import CartIcon from "../components/icons/CartIcon";
import StorageIcon from "../components/icons/StorageIcon";
import AddIcon from "../components/icons/AddIcon";
import PendingActionsIcon from "../components/icons/PendingActionsIcon";

export default function Admin({ title }) {
  usePage(title, true);

  const [pendingItems, setPendingItems] = useState(0);

  const [fetchCountPendingItems] = useFetching(async () => {
    const response = await CatalogService.countPendingItems();
    if (response.data.status) {
      setPendingItems(response.data.response.count);
    }
  });

  const [switched, setChapter] = useSwitch({
    analytics: <AnalyticsAdmin/>,
    audit: <AuditAdmin/>,
    categories: <CategoriesAdmin/>,
    brands: <BrandsAdmin/>,
    shops: <ShopsAdmin/>,
    createitem: <CreateItemAdmin fetchCountPendingItems={fetchCountPendingItems}/>,
    pendingitems: <PendingItemsAdmin fetchCountPendingItems={fetchCountPendingItems}/>,
    controlitems: <ControlItemsAdmin fetchCountPendingItems={fetchCountPendingItems}/>
  })

  useEffect(() => {
    fetchCountPendingItems();
  }, []);

  return (
    <section className="content margin-header">
      <Split className="container">
        <SplitBlock type="fixed" width="300px">
          <Block>
            <FixedMenu>
              <FixedMenuButton className="style--hover-main-accent" onClick={() => setChapter('analytics')}>
                <AnalyticsIcon />
                <span>Аналитика</span>
              </FixedMenuButton>
              <FixedMenuButton className="style--hover-main-accent" onClick={() => setChapter('audit')}>
                <EventListIcon />
                <span>Аудит событий</span>
              </FixedMenuButton>
            </FixedMenu>
          </Block>
          <Block>
            <FixedMenu>
              <FixedMenuButton className="style--hover-main-accent" onClick={() => setChapter('categories')}>
                <CategoryIcon />
                <span>Категории</span>
              </FixedMenuButton>
              <FixedMenuButton className="style--hover-main-accent" onClick={() => setChapter('brands')}>
                <DiamondIcon />
                <span>Бренды</span>
              </FixedMenuButton>
              <FixedMenuButton className="style--hover-main-accent" onClick={() => setChapter('shops')}>
                <CartIcon />
                <span>Магазины</span>
              </FixedMenuButton>
            </FixedMenu>
          </Block>
          <Block>
            <FixedMenu>
              <FixedMenuButton className="style--hover-main-accent" onClick={() => setChapter('createitem')}>
                <AddIcon />
                <span>Добавить товар</span>
              </FixedMenuButton>
              <FixedMenuButton className="style--hover-main-accent" onClick={() => setChapter('pendingitems')}>
                <PendingActionsIcon />
                <span>Подтверждение товаров</span>
                {
                  pendingItems !== 0 &&
                  <strong className="style--main-accent">{pendingItems}</strong>
                }
              </FixedMenuButton>
              <FixedMenuButton className="style--hover-main-accent" onClick={() => setChapter('controlitems')}>
                <StorageIcon />
                <span>Управление товарами</span>
              </FixedMenuButton>
            </FixedMenu>
          </Block>
          <span style={{display: "block", width: "100%", textAlign: "center", opacity: "0.5"}} className="style--freeze-lite">v. {process.env.REACT_APP_VERSION}</span>
        </SplitBlock>
        <SplitBlock type="grow">
          {switched}
        </SplitBlock>
      </Split>
    </section>
  );
}
