import React from 'react';
import { Link } from 'react-router-dom';

import { usePage } from '../hooks/usePage';

import IndexInfoCards from '../components/IndexInfoCards';
import AboutShops from '../components/AboutShops';

export default function NotFound() {
  usePage("Страница не найдена - Мир техники", true);

  return (
    <section className="content container margin-header">
      <div className="not-found">
        <strong>Страница не найдена</strong>
        <span>Проверьте адрес страницы</span>
        <Link className="style--main-accent" to="/">На главную</Link>
      </div>
      <AboutShops/>
      <IndexInfoCards/>
    </section>
  );
}