import instance from "./AxiosInstance";

export default class CategoryService {
  static async getAll() {
    return await instance.get(`/category`);
  }
  static async create(data) {
    return await instance.post(`/category/create`, data);
  }
  static async updateIcon(formData) {
    return await instance.put(`/category/update/icon`, formData);
  }
  static async delete(id) {
    return await instance.delete(`/category/delete`, {data: {id}});
  }
}