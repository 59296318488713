import { useState } from "react";

export const useSwitch = (chapters) => {

  const [switched, setSwitched] = useState(chapters[Object.keys(chapters)[0]]);

  function setChapter(name) {
    try {
      setSwitched(chapters[name]);
    } catch {}
  }

  return [switched, setChapter];
}