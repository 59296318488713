import React, { useState, useEffect, useContext } from "react";
import { YMaps, Map, Placemark } from 'react-yandex-maps';

import MainContext from "../../contexts/MainContext";

import { useFetching } from "../../hooks/useFetching";
import ShopService from "../../api/ShopService";

import Block, { BlockHeader } from "../../components/UI/Block/Block";

import TrashIcon from "../icons/TrashIcon";

export default function ShopsAdmin() {

  const maincontext = useContext(MainContext);

  const [shops, setShops] = useState([]);
  const [rawImages, setRawImages] = useState([]);
  const [shop, setShop] = useState({
    name: "",
    phone: "",
    geometry: {
      latitude: "44.041552",
      longitude: "43.046541",
      adress: ""
    },
    worktime: {
      weekdays: "",
      weekends: ""
    },
  })

  function setCoords(e) {
    const coords = e.get('coords');
    setShop({...shop, geometry: {...shop.geometry, latitude: coords[0], longitude: coords[1]}});
  }

  const [fetchShops, isShopsLoad] = useFetching(async () => {
    const response = await ShopService.getAll();
    if (response.data.status) {
      setShops(response.data.response);
    } else {
      setShops([]);
    }
  });

  const [createShop] = useFetching(async (data, image) => {
    const formData = new FormData();
    Array.from(image).forEach((file, index) => {
      formData.append(index, file);
    })
    formData.append("data", JSON.stringify(data));
    const response = await ShopService.create(formData);
    if (response.data.status) {
      maincontext.createNotification({
        title: "Успешно!", 
        subtitle: `Магазин "${data.name}" добавлен`,
        style: "style--positive"
      });
      fetchShops();
    } else {
      maincontext.createNotification({
        title: "Ошибка", 
        subtitle: `Ошибка при создании магазина, проверьте все поля ввода и формат изображений`,
        style: "style--negative"
      })
    }
  });

  const [deleteShop] = useFetching(async (id, name) => {
    const response = await ShopService.delete(id);
    if (response.data.status) {
      maincontext.createNotification({
        title: "Успешно!", 
        subtitle: `Магазин "${name}" удалён`,
        style: "style--positive"
      })
      fetchShops();
    }
  });

  function imageConvert(e) {
    setRawImages(e.target.files);
  }

  useEffect(() => {
    fetchShops();
  }, [])

  return (
    <>
      <Block className="admin">
        <BlockHeader>
          <strong>Добавить магазин</strong>
        </BlockHeader>
        <div className="admin__row">
          <input onChange={e => setShop({...shop, name: e.target.value})} className="admin__row-input" placeholder="Имя магазина" type="text" />
        </div>
        <div className="admin__row">
          <input onChange={e => setShop({...shop, phone: e.target.value})} className="admin__row-input" placeholder="Контактный телефон" type="text" />
        </div>
        <div className="admin__row">
          <input onChange={e => imageConvert(e)} className="admin__row-input" placeholder="Изображение" accept="image/png,image/jpeg" type="file" />
        </div>
        <span>Рабочий график</span>
        <div className="admin__row">
          <input onChange={e => setShop({...shop, worktime: {...shop.worktime, weekdays: e.target.value}})} className="admin__row-input" placeholder="ПН-ПТ" type="text" />
          <input onChange={e => setShop({...shop, worktime: {...shop.worktime, weekends: e.target.value}})} className="admin__row-input" placeholder="СБ" type="text" />
        </div>
        <span>Геометка на карте</span>
        <div className="admin__row">
          <input value={shop.geometry.latitude} onChange={e => setShop({...shop, geometry: {...shop.geometry, latitude: e.target.value}})} className="admin__row-input" placeholder="Latitude" type="text" />
          <input value={shop.geometry.longitude} onChange={e => setShop({...shop, geometry: {...shop.geometry, longitude: e.target.value}})} className="admin__row-input" placeholder="Longitude" type="text" />
        </div>
        <div className="admin__row">
          <input onChange={e => setShop({...shop, geometry: {...shop.geometry, adress: e.target.value}})} className="admin__row-input" placeholder="Адрес" type="text" />
        </div>
        <div className="map">
          <YMaps className="map__map">
            <Map onClick={e => setCoords(e)} style={{height: "300px"}} className="shops-preview__map" state={{center: [shop.geometry.latitude, shop.geometry.longitude], zoom: 16}}>
              <Placemark geometry={[shop.geometry.latitude, shop.geometry.longitude]} properties={{iconCaption: shop.name}}/>
            </Map>
          </YMaps>
        </div>
        <div className="admin__btns">
          <button onClick={() => createShop(shop, rawImages)} className="style--positive">Добавить</button>
        </div>
      </Block>
      <Block className="admin-table">
        <BlockHeader>
          <strong>Добавленные Магазины</strong>
        </BlockHeader>
        {
          shops.map((item, index) => 
            <div key={index} className="inner-block admin-table__row">
              <img  className="admin-table__row-icon" src={item.image} alt="" />
              <strong>{item.name}</strong>
              <span className="style--freeze-lite" style={{marginLeft: "7px"}}>{item.geometry?.adress}</span>
              <div className="admin-table__row-btns">
                <button className="style--hover-negative" onClick={() => deleteShop(item.id, item.name)}>
                  <TrashIcon/>
                </button>
              </div>
            </div>
          )
        }
      </Block>
    </>
  );
}
