import React, { useEffect, useState, useRef } from "react";

import { useClickOver } from "../../../hooks/useClickOver";

export default function SearchInput({items, preview=false, listIcon, onChange, onListClick, ...props}) {

  const [search, setSearch] = useState("");
  const [foundItems, setFoundItems] = useState(preview ? items : []);
  const [list, setList] = useState(false);
  
  const listRef = useRef(null);

  useClickOver(listRef, () => {
    setList(false);
  });

  useEffect(() => {
    if (preview && search === "" && items.length !== 0) {
      setFoundItems(items)
    } else if (search !== "") {
      setFoundItems(items.filter(item => item.name.toUpperCase().includes(search.toUpperCase())));
    }
  }, [search, setSearch, items])

  return (
    <div ref={listRef} onClick={() => setList(!list)} style={foundItems.length === 0 ? {borderRadius: "5px"} : {}} className={list ? "input-list input-list--active" : "input-list"}>
      <input {...props} onChange={e => {setSearch(e.target.value); if (onChange) onChange(e.target.value)}} type="text"/>
      <div className={list ? "input-list__list input-list__list--active" : "input-list__list"}>
        {
          foundItems.map((item) => 
            <button onClick={() => {item.onClick ? item.onClick(item) : onListClick(item)}} key={item.identifier} className="input-list__item">
              {
              item.icon 
              ? typeof item.icon === "string" ? <img src={item.icon} alt="" /> : item.icon
              : typeof listIcon === "string" ? <img src={listIcon} alt="" /> : listIcon
              }
              <span>{item.name}</span>
              {
                item.about 
                && <span className="style--second-accent-lite input-list__item-about">- {item.about}</span>
              }
            </button>
          )
        }
      </div>
    </div>
  );
}

