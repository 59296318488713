import React, { useState, useContext } from "react";
import { YMaps, Map, Placemark } from 'react-yandex-maps';

import DataContext from "../contexts/DataContext";

import Block from "./UI/Block/Block";
import FixedMenu, { FixedMenuButton } from "./UI/FixedMenu/FixedMenu";
import Adaptive from "./UI/Adaptive/Adaptive";

export default function ShopsPreviewModal({itemLink=null}) {
  const dataContext = useContext(DataContext);

  const [center, setCenter] = useState([dataContext.shops.data[0]?.geometry?.latitude || 44.039601, dataContext.shops.data[0]?.geometry?.longitude || 43.123994]);
  const [selectedShop, setSelectedShop] = useState(0);

  return (
    <Block className="shops-preview">
      <YMaps>
        <Map className="shops-preview__map" state={{center: center, zoom: 16}}>
        {
          dataContext.shops.data.map((item, index) => 
            <Placemark key={index} geometry={[item.geometry.latitude, item.geometry.longitude]} properties={{iconCaption: item.name}}/>
          )
        }
        </Map>
      </YMaps>
      <div className="shops-preview__list">
        <div className="shops-preview__list-header inner-block">
          <strong>Поиск по магазинам</strong>
          <span>Магазины в г.Пятигорске</span>
        </div>
        {
          itemLink &&
          <Adaptive maxWidth={1100}>
            <div className="shops-preview__buy">
              <a className="style--ozon" href={itemLink}>Купить на Ozon</a>
            </div>
          </Adaptive>
        }
        <FixedMenu>
          {
            dataContext.shops.data.map((item, index) => 
            <React.Fragment key={index}>
              <FixedMenuButton onClick={() => {
                setCenter([item.geometry.latitude, item.geometry.longitude]);
                setSelectedShop(index);
                }} className={`shops-preview__btn ${index === selectedShop ? "style--alert-main-accent" : "style--hover-main-accent"}`}>
                <span className="shops-preview__btn-title">{item.geometry.adress}</span>
                <div className="shops-preview__btn-subtitle">
                  <span>ПН-ПТ: {item.worktime.weekdays}</span>
                  <span>СБ: {item.worktime.weekends}</span>
                  <span>ВС: Выходной</span>
                </div>
              </FixedMenuButton>
              {Object.keys(dataContext.shops.data).length !== index + 1 && <div className="shops-preview__hr"></div>}
            </React.Fragment>
            )
          }
        </FixedMenu>
        {
          itemLink &&
          <Adaptive minWidth={1100}>
            <div className="shops-preview__buy">
              <a className="style--ozon" href={itemLink}>Купить на Ozon</a>
            </div>
          </Adaptive>
        }
      </div>
  </Block>
  );
}