import instance from "./AxiosInstance";

export default class ShopService {
  static async getAll() {
    return await instance.get(`/shop`);
  }
  static async create(data) {
    return await instance.post(`/shop/create`, data);
  }
  static async delete(id) {
    return await instance.delete(`/shop/delete`, {data: {id}});
  }
}