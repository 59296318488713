import React from "react";

export default function Table({items=[]}) {
  return (
    <div className="table">
      {
        items.map((item, index) =>
        <div key={index} className="table__group">
          <strong>{item.name}</strong>
          {
            item.items.map((item, index) =>
            <div key={index} className="table__item">
              <span className="table__item-value">{item.name}</span>
              <span>{item.value}</span>
            </div>
            )
          }
        </div> 
        )
      }
    </div>
  );
}
