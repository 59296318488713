import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Split, { SplitBlock } from "./UI/Split/Split";
import Block from "./UI/Block/Block";
import Modal from "./UI/Modal/Modal";

import AboutServices from "./AboutServices";

export default function IndexInfoCards() {

  const router = useNavigate();

  const [previewService, setPreviewService] = useState(false);

  return (
    <>
      <Split>
        <SplitBlock type="fixed" width="33.33%">
          <Block className="info-card" style={{height: "150px"}}>
            <strong>Гарантия и сервис</strong>
            <span>На всю приобретаемую у нас технику STIHL действует гарантия 1 год от производителя</span>
            <div>
              <button onClick={() => setPreviewService(true)} className="style--text-outline">Подробнее</button>
            </div>
          </Block>
        </SplitBlock>
        <SplitBlock type="grow">
          <Block className="info-card" style={{height: "150px"}}>
            <strong>Каталог</strong>
            <span>Подберите всё необходимое в нашем каталоге</span>
            <div>
              <button onClick={() => router("/catalog")} className="style--text-outline">Перейти</button>
            </div>
          </Block>
        </SplitBlock>
        <SplitBlock type="fixed" width="33.33%">
          <Block className="info-card" style={{height: "150px"}}>
            <strong>Категории</strong>
            <span>Список доступных брендов и категорий</span>
            <div>
              <button onClick={() => router("/categories")} className="style--text-outline">Перейти</button>
            </div>
          </Block>
        </SplitBlock>
      </Split>
      <Modal visible={previewService} setVisible={setPreviewService}>
        <AboutServices />
      </Modal>
    </>
  );
}
