import instance from "./AxiosInstance";

export default class AnalyticsService {
  static async getWeekRequests() {
    return await instance.get(`/analytics/request/week`);
  }
  static async getCategoriesPercents() {
    return await instance.get(`/analytics/other/categories`);
  }
  static async getBrandsPercents() {
    return await instance.get(`/analytics/other/brands`);
  }
  static async getByQuery(name, type, mindate = null, maxdate = null) {
    return await instance.get(`/analytics/other/get`, {
      params: {
        name, type, mindate, maxdate
      }
    });
  }
}