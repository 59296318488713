import React, { useState, useEffect } from "react";

import SmallArrowIcon from "../../icons/SmallArrowIcon";

export default function Gallery({images=[]}) {
  const [currentImage, setCurrentImage] = useState(images.length !== 0 ? images[0].url : "");

  function changeImage(direction) {
    try {
      setCurrentImage(images[images.findIndex(item => item.url === currentImage) + direction].url);
    } catch {
      setCurrentImage(images[direction > 0 ? 0 : images.length - 1].url);
    }
  }

  useEffect(() => {
    setCurrentImage(images.length !== 0 ? images[0].url : "");
  }, [images]);

  return (
    <div className="gallery">
      <div className="gallery__image">
        <img src={currentImage} alt="" />
        {
          images.length !== 1 &&
          <div className="gallery__controls">
            <button className="gallery__controls-button style--hover-main-accent" style={{left: "0"}} onClick={() => changeImage(-1)}>
              <SmallArrowIcon rotate="90deg"/>
            </button>
            <button className="gallery__controls-button style--hover-main-accent" style={{right: "0"}} onClick={() => changeImage(1)}>
              <SmallArrowIcon rotate="270deg"/>
            </button>
          </div>
        }
      </div>
      <div className="gallery__images">
        {
          images.map((image, index) => 
            <button key={index} onClick={() => setCurrentImage(image.url)}>
              <img src={image.url} alt={image.title} />
            </button>
          )
        }
      </div>
    </div>
  )
}