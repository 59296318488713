import React, { useState, useEffect, useContext } from "react";

import MainContext from "../../contexts/MainContext";

import { useFetching } from "../../hooks/useFetching";
import CategoryService from "../../api/CategoryService";

import Block, { BlockHeader } from "../../components/UI/Block/Block";
import LabelInput from "../UI/LabelInput/LabelInput";

import TrashIcon from "../icons/TrashIcon";
import UpdateIcon from "../icons/UpdateIcon";

export default function CategoriesAdmin() {

  const maincontext = useContext(MainContext);

  const [categories, setCategories] = useState([]);
  const [rawImages, setRawImages] = useState([]);
  const [category, setCategory] = useState({
    name: ""
  })

  const [fetchCategories, isCategoriesLoad] = useFetching(async () => {
    const response = await CategoryService.getAll();
    if (response.data.status) {
      setCategories(response.data.response);
    }
  });

  const [createCategory] = useFetching(async (data, image) => {
    const formData = new FormData();
    Array.from(image).forEach((file, index) => {
      formData.append(index, file);
    })
    formData.append("data", JSON.stringify(data));
    const response = await CategoryService.create(formData);
    if (response.data.status) {
      maincontext.createNotification({
        title: "Успешно!", 
        subtitle: `Категория "${data.name}" создана`,
        style: "style--positive"
      });
      fetchCategories();
    } else {
      maincontext.createNotification({
        title: "Ошибка", 
        subtitle: `Ошибка при создании категории, проверьте все поля ввода и формат изображений`,
        style: "style--negative"
      })
    }
  });

  const [updateImage] = useFetching(async (id, inputEvent) => {
    const formData = new FormData();
    formData.append("image", Array.from(inputEvent.target.files)[0]);
    formData.append("id", id);
    const response = await CategoryService.updateIcon(formData);
    if (response.data.status) {
      maincontext.createNotification({
        title: "Успешно!", 
        subtitle: `Категория обновлена`,
        style: "style--positive"
      });
      fetchCategories();
    } else {
      maincontext.createNotification({
        title: "Ошибка", 
        subtitle: `Ошибка при обновлении категории`,
        style: "style--negative"
      })
    }
  });

  const [deleteCategory] = useFetching(async (id, name) => {
    const response = await CategoryService.delete(id);
    if (response.data.status) {
      maincontext.createNotification({
        title: "Успешно!", 
        subtitle: `Категория "${name}" удалена`,
        style: "style--positive"
      })
      fetchCategories();
    }
  });

  function imageConvert(e) {
    setRawImages(e.target.files);
  }

  useEffect(() => {
    fetchCategories();
  }, [])

  return (
    <>
      <Block className="admin">
        <BlockHeader>
          <strong>Добавить категорию</strong>
        </BlockHeader>
        <div className="admin__row">
          <input onChange={e => setCategory({...category, name: e.target.value})} className="admin__row-input" placeholder="Имя" type="text" />
        </div>
        <div className="admin__row">
          <input onChange={e => imageConvert(e)} className="admin__row-input" placeholder="Изображения" accept="image/png,image/jpeg" type="file"/>
        </div>
        <div className="admin__btns">
          <button onClick={() => createCategory(category, rawImages)} className="style--positive">Добавить</button>
        </div>
      </Block>
      <Block className="admin-table">
        <BlockHeader>
          <strong>Добавленные категории</strong>
        </BlockHeader>
        {
          categories.map((item, index) => 
            <div key={index} className="inner-block admin-table__row">
              <img  className="admin-table__row-icon" src={item.icon} alt="" />
              <strong>{item.name}</strong>
              <div className="admin-table__row-btns">
                <LabelInput
                  id={`image-update-${item.id}`}
                  inputProps={{
                    type: "file",
                    multiple: false,
                    style: {display: "none"},
                    accept: "image/png,image/jpeg",
                    onChange: e => updateImage(item.id, e)
                  }}
                  labelProps={{
                    className: "style--hover-second-accent"
                  }}
                >
                  <UpdateIcon/>
                </LabelInput>
                <button className="style--hover-negative" onClick={() => deleteCategory(item.id, item.name)}>
                  <TrashIcon/>
                </button>
              </div>
            </div>
          )
        }
      </Block>
    </>
  );
}
