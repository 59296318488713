import React, { useState } from "react";

import "../../node_modules/highlight.js/styles/a11y-light.css";

import Block from "./UI/Block/Block";
import Code from "./UI/Code/Code";

import ErrorIcon from "./icons/ErrorIcon"; 
import CheckIcon from "./icons/CheckIcon";

export default function AuditItem({item}) {
  const [openData, setOpenData] = useState(false);

  return (
    <Block className="audit-item" onClick={() => setOpenData(!openData)}>
      <div className="audit-item__header unselectable">
        <div className="audit-item__title">
          {
            item.status === "success"
            ? <CheckIcon fill="style--fill-positive"/>
            : <ErrorIcon fill="style--fill-negative"/>
          }
          <span>{item.name}</span>
        </div>
        <span className="audit-item__date">{item.date}</span> 
      </div>
      <div className={openData ? "audit-item__data audit-item--open" : "audit-item__data"}>
        <span>{item.description}</span>
        <Code lang="json" onClick={(e) => e.stopPropagation()}>
          {JSON.stringify(item.data, undefined, 2)}
        </Code>
      </div>
    </Block>
  );
}