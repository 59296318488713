import React, { useState } from "react";

import SmallArrowIcon from "../../icons/SmallArrowIcon";

export default function Accordion({children, title, state=false, className=""}) {

  const [open, setOpen] = useState(state);

  return (
    <div className={className !== "" ? `accordion ${className}` : "accordion"}>
      <button onClick={() => setOpen(!open)} className="accordion__header style--hover-main-accent">
        <span>{title}</span>
        {
          open
          ? <SmallArrowIcon/>
          : <SmallArrowIcon rotate="90deg"/>
        }
      </button>
      <div className={open ? "accordion__content accordion--open" : "accordion__content"}>
        {children}
      </div>
    </div>
  );
}
