import { useState } from "react";

import AuthService from "../api/AuthService";

export default function AuthStore() {
  const [user, setUser] = useState({});
  const [isAuth, setIsAuth] = useState(false);

  async function login(userLogin, password) {
    const response = await AuthService.login(userLogin, password);
    if (response.data.status) {
      setIsAuth(true);
      setUser(response.data.response);
      localStorage.setItem("accessToken", response.data.response.accessToken);
      return true;
    } else {
      return false;
    }
  }

  async function refresh() {
    const response = await AuthService.refresh();
    if (response.data.status) {
      this.setIsAuth(true);
      this.setUser(response.data.response);
      localStorage.setItem("accessToken", response.data.response.accessToken);
      return true;
    } else {
      return false;
    }
  }

  async function logout() {
    const response = await AuthService.logout();
    if (response.data.status) {
      localStorage.removeItem("accessToken");
      this.setIsAuth(false);
      this.setUser({});
      return true;
    } else {
      return false;
    }
  }

  return {user, setUser, isAuth, setIsAuth, login, refresh, logout};
}