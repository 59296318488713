import { useReducer } from "react";

import CategoryService from "../api/CategoryService";
import BrandService from "../api/BrandService";
import ShopService from "../api/ShopService";

const defaultState = {
  isLoad: false,
  error: false,
  data: []
}

const errorState = {
  isLoad: true,
  error: true,
  data: []
}

function createInitialState() {
  return {
    categories: defaultState,
    brands: defaultState,
    shops: defaultState
  }
}

function dataReducer(state, action) {
  return {...state, ...action};
}

export default function DataStore() {
  const [dataState, dispatch] = useReducer(dataReducer, {}, createInitialState);

  function update() {
    try {
      CategoryService.getAll().then(response => {
        if (response.data.status) {
          dispatch({categories: {isLoad: true, data: response.data.response}});
        } else dispatch({categories: errorState});
      });
    } catch {
      dispatch({categories: errorState});
    }

    try {
      BrandService.getAll().then(response => {
        if (response.data.status) {
          dispatch({brands: {isLoad: true, data: response.data.response}});
        } else dispatch({brands: errorState});
      });
    } catch {
      dispatch({brands: errorState});
    }

    try {
      ShopService.getAll().then(response => {
        if (response.data.status) {
          dispatch({shops: {isLoad: true, data: response.data.response}});
        } else dispatch({shops: errorState});
      });
    } catch {
      dispatch({shops: errorState});
    }
  }

  return {update, ...dataState};
}