import instance from "./AxiosInstance";

export default class AuthService {
  static async login(login, password) {
    return await instance.post(`/user/login`, {login, password});
  }
  static async refresh() {
    return await instance.get(`/user/refresh`);
  }
  static async logout() {
    return await instance.delete(`/user/logout`);
  }
}