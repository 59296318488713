import React from "react";

export default function SwitchButton({className="", onChange, value, ...props}) {

  function check() {
    return onChange(!value);
  }

  return (
    <label className={"switch-button " + className}>
      <input {...props} onChange={check} checked={value} type="checkbox"/>
      <span className="switch-button__slider"></span>
    </label>
  );
}

