import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="inner-footer">
        <div className="footer__container">
          <div className="footer__logo">
            <strong>{process.env.REACT_APP_NAME}</strong>
            <span>г. Пятигорск</span>
            <a href="tel:79288150460">+7 (928) 815-04-60</a>
            <a href="tel:79286332220">+7 (928) 633-22-20</a>
          </div>
          <div className="footer__nav">
            <Link className="footer__link" to="/">Главная</Link>
            <Link className="footer__link" to="/categories">Категории</Link>
            <Link className="footer__link" to="/catalog">Каталог</Link>
            <Link className="footer__link" to="/legal">Политика</Link>
            <Link className="footer__link" to="/legal">Правообладателям</Link>
          </div>
        </div>
        <div className="footer__container">
          <div className="footer__company">
            <div className="footer__company-column">
              <span>ИП Баранов Виталий Сергеевич</span>
              <span>ИНН 261808740629 | ОГРН 318265100102950</span> 
            </div>
            <div className="footer__company-column">
              <span>Все права защищены © Магазин "Мир техники" 2023</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}