import React, { useEffect, useState } from "react";

import { usePage } from "../hooks/usePage";
import { useFetching } from "../hooks/useFetching";

import CatalogService from "../api/CatalogService";

import { getCategories } from "../utils/LastCategories";

import Split, { SplitBlock } from "../components/UI/Split/Split";

import Categories from "../components/Categories";
import AboutShops from "../components/AboutShops";
import AboutBrands from "../components/AboutBrands";
import IndexBanners from "../components/IndexBanners";
import IndexInfoCards from "../components/IndexInfoCards";

import ItemPreviewCompact from "../components/ItemPreviewCompact";
import HorizontalScroll from "../components/UI/HorizontalScroll/HorizontalScroll";

export default function Index({title}) {
  usePage(title, true);

  const [topItems, setTopItems] = useState([]);

  const [fetchCatalog, isCatalogLoad] = useFetching(async () => {
    const categories = getCategories();
    const response = await CatalogService.getPage(0, {
      search: "",
      categories: categories,
      sort: {value: "asc"},
      group: {value: "brand"},
      price: {
        min: 0,
        max: 1000000
      }
    })
    if (response.data.status) {
      setTopItems(response.data.response.items);
    }
  });

  useEffect(() => {
    fetchCatalog();
  }, [])

  return (
    <section className="content container margin-header">
      <Split m_reverse={true}>
        <SplitBlock type="fixed" width="350px">
          <Categories />
        </SplitBlock>  
        <SplitBlock type="grow">
          <IndexBanners/>
          <HorizontalScroll step={200}>
            <AboutBrands/>
          </HorizontalScroll>
        </SplitBlock>
      </Split>
      <div style={{marginBottom: "25px"}}>
        <strong style={{fontSize: "150%", marginLeft: "3px"}}>Может быть интересно</strong>
        <HorizontalScroll step={300}>
          {
            !isCatalogLoad &&
              topItems.map((item, index) => 
                <ItemPreviewCompact key={index} item={item}/>
              )
          }
        </HorizontalScroll>
      </div>
      <AboutShops/>
      <IndexInfoCards/>
    </section>
  );
}