import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import AuthContext from "../contexts/AuthContext";

import Login from "./Login";
import Modal from "./UI/Modal/Modal";
import Avatar from "./UI/Avatar/Avatar";
import Block from "./UI/Block/Block";
import DropDown, { DropDownContent } from "./UI/DropDown/DropDown";

import AccountIcon from "./icons/AccountIcon";
import LogoutIcon from "./icons/LogoutIcon";
import StorageIcon from "./icons/StorageIcon";
import HeartIcon from "./icons/HeartIcon";

export default function UserMenu() {

  const authContext = useContext(AuthContext);

  const router = useNavigate();

  const [login, setLogin] = useState(false);

  return ( 
    <>
      <DropDown style={{direction: "rtl"}}>
        <div style={{cursor: "pointer"}}>
          <Avatar type="text" content={authContext.isAuth ? authContext.user.login[0].toUpperCase() : "Г"} size="43px" />
        </div>
        <DropDownContent style={{marginTop: "15px"}}>
          <Block className="user-menu">
            <div className="user-menu__whois">
              <Avatar type="text" content={authContext.isAuth ? authContext.user.login[0].toUpperCase() : "Г"} size="75px" fontSize="150%" />
              {
                authContext.isAuth &&
                <span className="user-menu__whois-login style--freeze-lite">@{authContext.user.login}</span>
              }
              <strong className="user-menu__whois-name">{authContext.isAuth ? authContext.user.firstName + " " + authContext.user.lastName : "Гость"}</strong>
            </div>
            <div className="inner-block user-menu__nav">
              {
                authContext.isAuth &&
                <button className="style--hover-main-accent" onClick={() => router('/account')}>
                  <AccountIcon />
                  <span>Аккаунт</span>
                </button>
              }
              <button className="style--hover-main-accent" onClick={() => router('/favorites')}>
                <HeartIcon />
                <span>Избранное</span>
              </button>
            </div>
            {
              authContext.isAuth
              ? <>
                  {
                    authContext.user.isAdmin &&
                    <button onClick={() => router('/admin')} className="user-menu__btn style--positive">
                      <StorageIcon fill="style--fill-white"/>
                      <span>Управление</span>
                    </button>
                  }
                  <button onClick={() => authContext.logout()} className="user-menu__btn style--negative">
                    <LogoutIcon fill="style--fill-white"/>
                    <span>Выйти</span>
                  </button>
                </>
              : <button onClick={() => setLogin(!login)} className="user-menu__btn style--positive">
                  <AccountIcon fill="style--fill-white"/>
                  <span>Войти</span>
                </button>
            }
          </Block>
        </DropDownContent>
      </DropDown>
      <Modal visible={login} setVisible={setLogin}>
        <Login setVisible={setLogin}></Login>
      </Modal>
    </>
  );
}