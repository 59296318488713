import instance from "./AxiosInstance";

export default class CatalogService {
  static async getPage(page=0, filters, status="success") {
    return await instance.get('/catalog/get', {
      params: {
        page: page,
        search: filters.search,
        categories: JSON.stringify(filters.categories || []),
        brands: JSON.stringify(filters.brands || []),
        sort: filters.sort.value,
        group: filters.group.value,
        min: filters.price.min,
        max: filters.price.max,
        target: status
      }
    });
  }
  static async getByIdentifier(identifier) {
    return await instance.get(`/catalog/get/${identifier}`);
  }
  static async getItemsByIds(page=0, filters, ids) {
    return await instance.get(`/catalog/get/ids`, {
      params: {
        ids: ids,
        filters: {
          page: page,
          search: filters.search,
          categories: JSON.stringify(filters.categories || []),
          brands: JSON.stringify(filters.brands || []),
          sort: filters.sort.value,
          group: filters.group.value,
          min: filters.price.min,
          max: filters.price.max, 
        }
      }
    });
  }
  static async getTips({search, category, brand}) {
    return await instance.get(`/catalog/tips`, {
      params: {
        search: search || null,
        category: category || null,
        brand: brand || null
      }
    });
  }
  static async getEvents() {
    return await instance.get(`/catalog/events`);
  }
  static async countPendingItems() {
    return await instance.get(`/catalog/pending/count`);
  }
  static async setStatus(id, status) {
    return await instance.put(`/catalog/status`, {id, status});
  }
  static async fetchSeller() {
    return await instance.post(`/catalog/seller/fetch`);
  }
  static async getSellerById(id) {
    return await instance.get(`/catalog/seller/get/${id}`)
  }
  static async create(formData) {
    return await instance.post(`/catalog/create`, formData);
  }
  static async update(formData) {
    return await instance.put(`/catalog/update`, formData);
  }
  static async delete(id) {
    return await instance.delete(`/catalog/delete`, {data: {id}});
  }
}