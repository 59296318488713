import React, { useState, useEffect, useContext } from "react";

import MainContext from "../../contexts/MainContext";

import { useFetching } from "../../hooks/useFetching";
import CatalogService from "../../api/CatalogService";

import SearchSort from "../SearchSort";
import Pagination from "../UI/Pagination/Pagination";
import Modal from "../UI/Modal/Modal";
import ItemBuilder from "../ItemBuilder";

import ItemControlPreview from "../ItemControlPreview";

export default function ControlItemsAdmin({fetchCountPendingItems}) {
  const maincontext = useContext(MainContext);

  const [defaultFilters, setDefaultFilters] = useState({
    search: "",
    categories: [],
    brands: [],
    sort: {name: "По возрастанию", value: "ASC"},
    group: {name: "По бренду", value: "brand"},
    price: {
      min: 0,
      max: 100000000
    }
  });

  const [filters, setFilters] = useState(defaultFilters);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [tips, setTips] = useState([]);

  const [modalUpdate, setModalUpdate] = useState(false);
  const [itemData, setItemData] = useState(undefined);

  const [items, setItems] = useState([]);

  const [fetchCatalog, isCatalogLoad] = useFetching(async (page, filters) => {
    const response = await CatalogService.getPage(page - 1, filters);
    if (response.data.status) {
      setPage(page);
      setItems(response.data.response.items);
      setTotalPages(response.data.response.totalPagesCount);
      setTips(response.data.response.tips);
    } else {
      setItems([]);
    }
  });

  const [deleteItem] = useFetching(async (item) => {
    const response = await CatalogService.delete(item.id);
    if (response.data.status) {
      maincontext.createNotification({
        title: "Успешно!", 
        subtitle: `Товар "${item.name}" удалён`,
        style: "style--positive"
      })
      fetchCatalog(page, filters);
    }
  });

  const [pendingItem] = useFetching(async (item) => {
    const response = await CatalogService.setStatus(item.id, "pending");
    if (response.data.status) {
      maincontext.createNotification({
        title: "Успешно!", 
        subtitle: `Товар "${item.name}" перенесён в неподверждённые товары`,
        style: "style--positive"
      })
      fetchCatalog(page, filters);
      fetchCountPendingItems();
    }
  });

  function itemUpdate(item) {
    setItemData(item);
    setModalUpdate(true);
  }

  useEffect(() => {
    fetchCatalog(1, filters);
  }, [filters])

  return (
    <>
      <SearchSort filters={filters} setFilters={setFilters} tips={tips}/>
      {
        items.map((item, index) => 
          <ItemControlPreview key={index} item={item} onDelete={deleteItem} onPending={pendingItem} onUpdate={itemUpdate}></ItemControlPreview>
        )
      }
      <Pagination currentPage={page} totalPages={totalPages} range={5} changePage={page => fetchCatalog(page, filters)}/>
      <Modal visible={modalUpdate} setVisible={setModalUpdate}>
        <ItemBuilder itemdata={itemData} update={true} onBuild={() => {setModalUpdate(false); fetchCatalog(page, filters);}}/>
      </Modal>
    </>
  );
}
