import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { addToFavorites, checkFavorites } from "../utils/Favorites";

import Block from "./UI/Block/Block";
import Tags from "./UI/Tags/Tags";

import HeartFillIcon from "./icons/HeartFillIcon";

export default function ItemPreview({item}) {
  const router = useNavigate();

  const [inFavorites, setInFavorites] = useState(false); 

  useEffect(() => {
    setInFavorites(checkFavorites(item.id));
  }, [item]); 

  return(
    <Block className="item-preview" onClick={() => router(`/catalog/${item.identifier}`)}>
      <div className="item-preview__content">
        <div className="item-preview__image">
          <img src={item.attachments.images[0].url} alt="" />
        </div>
        <div className="item-preview__about">
          <strong className="item-preview__about-title">{item.name}</strong>
          <div className="item-preview__about-subtitle" dangerouslySetInnerHTML={{__html: item?.about}}></div>
          <Tags tagStyle="style--alert-main-accent" tags={item.attachments.tags}/>
          <span className={item?.seller?.indexing ? item?.seller?.stocks?.present === 0 ? "style--freeze-lite" : "style--positive-lite" : "style--positive-lite"}>
            {
              item?.seller?.indexing 
              ? item?.seller?.stocks?.present === 0 ? "Нет в наличии, уточните в магазине" : `В наличии: ${item?.seller?.stocks?.present}` 
              : "Наличие уточняйте в магазине"
            }
          </span>
        </div>
      </div>
      <div className="item-preview__price">
        <span className="item-preview__value">{item.price} ₽</span>
        {
          item.price > 5000 &&
          <span className="style--freeze-lite item-preview__label">Доступно в кредит</span>
        }
        <div className="item-preview__price-btns">
          {
            inFavorites
            ? <button className="style--alert-main-accent style--fill-main-accent item-preview__btn-icon" onClick={(e) => {e.stopPropagation(); addToFavorites(item.id); setInFavorites(!inFavorites)}}>
                <HeartFillIcon/>
              </button>
            : <button className="style--freeze style--fill-freeze item-preview__btn-icon" onClick={(e) => {e.stopPropagation(); addToFavorites(item.id); setInFavorites(!inFavorites)}}>
                <HeartFillIcon/>
              </button>
          }
          <button className="style--positive item-preview__btn-buy">Подробнее</button>
        </div>
      </div>
    </Block>
  );
}