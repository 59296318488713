import React, { useState, useEffect } from "react";

import Block from "../../UI/Block/Block";
import { PaginationArray } from "../../../utils/PaginationArray";

import BigArrowIcon from "../../icons/SmallArrowIcon";

export default function Pagination({currentPage, totalPages, range, changePage}) {

  const [pagesArray, setPagesArray] = useState(PaginationArray(currentPage, totalPages, range));

  useEffect(() => {
    setPagesArray(PaginationArray(currentPage, totalPages, range));
  }, [currentPage, totalPages]);

  return (
    <Block className="pagination">
      <button onClick={() => currentPage !== 1 ? changePage(currentPage - 1) : {}}><BigArrowIcon rotate="90deg"/></button>
      {
        pagesArray.map((page, index) => 
        <button key={index} onClick={() => currentPage !== page ? changePage(page) : {}} className={currentPage === page ? "style--alert-main-accent" : ""}>{page}</button>
        )
      }
      {
        currentPage < totalPages - range / 2 &&
        <>
          <span>...</span>
          <button onClick={() => changePage(totalPages)}>{totalPages}</button>
        </>
      }
      <button onClick={() => currentPage !== totalPages ? changePage(currentPage + 1) : {}}><BigArrowIcon rotate="270deg"/></button>
    </Block>
  );
}