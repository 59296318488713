import React from "react";

export default function Block({children, className, ...props}) {

  const rootClasses = ["block"];

  if (className) rootClasses.push(className);

  return (
    <div {...props} className={rootClasses.join(' ')}>
      {children}
    </div>
  );
}

export function BlockHeader({children}) {
  return (
    <div className="block__header">
      {children}
    </div>
  );
}

export function BlockNav({children}) {
  return (
    <div className="block__nav">
      {children}
    </div>
  );
}

export function BlockMenu({children}) {
  return (
    <div className="block__menu">
      {children}
    </div>
  );
}

