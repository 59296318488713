import React from "react";
import { Link } from "react-router-dom";

import Banner from '../components/UI/Banner/Banner';
import Carousel from '../components/UI/Carousel/Carousel.jsx';

export default function IndexBanners() {
  return (
    <Carousel interval={7000} autoPlay={true}>
      <Banner
        height="400px"
        image="https://static.tildacdn.com/tild6431-6335-4564-a163-666261656330/y_vasi-kropotkin_sti.jpg"
        blur={1}
      >
        <div className="banner-default">
          <strong>Время покупать STIHL</strong>
          <span>Качество проверенное временем и тысячами довольных клиентов. Выбери и ты свой инструмент!</span>
          <Link to="catalog" className="style--positive">Каталог</Link>
        </div>
      </Banner>
      <Banner
        height="400px"
        image="https://static.tildacdn.com/tild3630-3361-4933-b633-326161356364/y_vasi-kropotkin_sti.jpg"
        blur={1}
      >
        <div className="banner-default">
          <strong>Без проводов. Без запаха бензина.</strong>
          <span>Оцени качество и удобство работы с аккумуляторными агрегатами STIHL. Тихие. Лёгкие. Мощные.</span>
          <Link to="catalog" className="style--positive">Каталог</Link>
        </div>
      </Banner>
    </Carousel>   
  );
}