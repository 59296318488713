import { useState } from "react";

export const useNotifications = () => {

  const [notifications, setNotifications] = useState([]);

  function createNotification(item) {
    setNotifications([...notifications, item])
  }

  return [notifications, createNotification];
}