import Index from "../pages/Index";
import Catalog from "../pages/Catalog";
import Legal from "../pages/Legal";
import ItemPage from "../pages/ItemPage";
import Categories from "../pages/Categories";
import Admin from "../pages/Admin";
import Favorites from "../pages/Favorites";
import Account from "../pages/Account";
//import SignUp from "../pages/Signup";

const publicPaths = [
  {
    title: "Мир техники - Пятигорск | Широкий выбор бензо, электро и аккумуляторной техники от мирового бренда STIHL, Husqvarna",
    path: "/",
    component: Index,
    exact: true
  },
  {
    title: "Каталог - Мир техники | бензо, электро и аккумуляторная техника",
    path: "/catalog",
    component: Catalog,
    exact: true
  }, 
  {
    title: "Подробнее о товаре - Мир мехники",
    path: "/catalog/:identifier",
    component: ItemPage,
    exact: true
  },
  {
    title: "Политика - Мир техники",
    path: "/legal",
    component: Legal,
    exact: true
  },
  {
    title: "Категории - Мир техники",
    path: "/categories",
    component: Categories,
    exact: true
  },
  {
    title: "Избранное - Мир техники",
    path: "/favorites",
    component: Favorites,
    exact: true
  }
  // {
  //   title: "Регистрация аккаунта - Мир техники",
  //   path: "/signup",
  //   component: SignUp,
  //   exact: true
  // }
]

const privatePaths = [
  {
    title: "Управление аккаунтом - Мир техники",
    path: "/account",
    component: Account,
    exact: true
  }
];

const adminPaths = [
  {
    title: "Панель управления - Мир техники",
    path: "/admin",
    component: Admin,
    exact: true
  }
]

export { publicPaths, adminPaths, privatePaths };