import React from "react";

import Block from "./UI/Block/Block";
import Tags from "./UI/Tags/Tags";

import TrashIcon from "./icons/TrashIcon";
import UpdateIcon from "./icons/UpdateIcon";
import LinkIcon from "./icons/LinkIcon";
import PendingActionsIcon from "./icons/PendingActionsIcon";
import AddIcon from "./icons/AddIcon";
import ErrorIcon from "./icons/ErrorIcon";
import StorageIcon from "./icons/StorageIcon";

export default function ItemControlPreview({item, onPending, onUpdate, onDelete, type="success"}) {
  return(
    <Block className="item-preview" style={{cursor: "default"}}>
      <div className="item-preview__content">
        <div className="item-preview__image">
          <img src={item.attachments.images[0]?.url || item.category.icon} alt="" />
        </div>
        <div className="item-preview__about">
          <strong className={!item?.seller?.indexing ? "item-preview__about-title" : "item-preview__about-title style--second-accent-lite"}>{item.name}</strong>
          <div className="item-preview__about-subtitle" dangerouslySetInnerHTML={{__html: item?.about}}></div>
          <Tags tagStyle="style--alert-main-accent" tags={item.attachments.tags}/>
          <div style={{display: "flex", alignItems: "center"}}>
            <StorageIcon fill={item?.seller?.stocks?.present === 0 ? "style--fill-freeze" : "style--fill-positive"}/>
            <span style={{margin: "0px 10px"}} className={item?.seller?.stocks?.present === 0 ? "style--freeze-lite" : "style--positive-lite"}>
              {
                item?.seller?.indexing 
                ? item.seller.stocks.present === 0 ? "Нет в наличии" : `В наличии: ${item.seller.stocks.present}` 
                : "без индексации остатков"
              }
            </span>
            {
              item?.category?.id === "root-unknown" || item?.brand?.id === "root-unknown"
              ? <>
                  <ErrorIcon fill="style--fill-negative"/>
                  <span className="style--negative-lite" style={{marginLeft: "10px"}}>Ошибка заполнения</span>
                </>
              : ""
            }
          </div>
        </div>
      </div>
      <div className="item-preview__price">
        <span className="item-preview__value">{item.price} ₽</span>
        {
          item.price > 5000 &&
          <span className="style--freeze-lite item-preview__label">Доступно в кредит</span>
        }
        <div className="item-preview__price-btns">
          {
            type === "success" &&
            <button onClick={() => window.open(`${process.env.REACT_APP_URL}/catalog/${item?.identifier}`, '_blank').focus()} className="style--hover-main-accent item-preview__btn-icon" title="Перейти на страницу товара">
              <LinkIcon/>
            </button>
          }
          <button onClick={() => onPending(item)} className="style--hover-main-accent item-preview__btn-icon" title={type === "success" ? "Перенести в неподтверждённые" : "Перенести в подтверждённые"}>
            {
              type === "success"
              ? <PendingActionsIcon/>
              : <AddIcon/>
            }
          </button>
          <button onClick={() => onUpdate(item)} className="style--hover-main-accent item-preview__btn-icon" title="Изменить товар">
            <UpdateIcon/>
          </button>
          <button onClick={() => onDelete(item)} className="style--hover-negative item-preview__btn-icon" title="Удалить">
            <TrashIcon/>
          </button>
        </div>
      </div>
    </Block>
  );
}