import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import CatalogService from "../api/CatalogService";

import { useFetching } from "../hooks/useFetching";
import { usePage } from "../hooks/usePage";

import { getNoun } from "../utils/GetNoun";

import Split, { SplitBlock } from "../components/UI/Split/Split";
import SearchFilter from "../components/SearchFilter";
import SearchSort from "../components/SearchSort";
import ItemPreview from "../components/ItemPreview";
import Loader from "../components/UI/Loader/Loader";
import Alert from "../components/UI/Alert/Alert";
import Pagination from "../components/UI/Pagination/Pagination";
import Adaptive from "../components/UI/Adaptive/Adaptive";

export default function Catalog({title}) {
  usePage(title, true);

  const [searchParams, setSearchParams] = useSearchParams();

  const [defaultFilters, setDefaultFilters] = useState({
    search: searchParams.get('search') || "",
    categories: searchParams.get('categories') ? [searchParams.get('categories')] : [],
    brands: searchParams.get('brands') ? [searchParams.get('brands')] : [],
    sort: {name: "По возрастанию", value: "ASC"},
    group: {name: "По бренду", value: "brand"},
    price: {
      min: 0,
      max: 1000000
    }
  });

  const [items, setItems] = useState([]);
  const [tips, setTips] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filters, setFilters] = useState(defaultFilters);

  const [fetchItems, isItemsLoad] = useFetching(async (page, filters) => {
    const response = await CatalogService.getPage(page - 1, filters);
    if (response.data.status) {
      window.scrollTo(0, 0);
      setPage(page);
      setItems(response.data.response.items);
      setTotalPages(response.data.response.totalPagesCount);
      setTips(response.data.response.tips);
      setTotalItems(response.data.response.totalItems);
      // backend fix it
      //setDefaultFilters({...defaultFilters, price: {min: response.data.price.min || 0, max: response.data.price.max || 1000000}});
    } else {
      setItems([]);
      setTotalItems(0);
      setDefaultFilters({...defaultFilters, price: {min: 0, max: 1000000}});
    }
  });

  useEffect(() => {
    fetchItems(1, filters);
  }, [filters])

  useEffect(() => {
    const search = searchParams.get("search");
    const categories = searchParams.get("categories");
    if (search) {
      setFilters({...defaultFilters, search})
    }
    if (categories) {
      setFilters({...defaultFilters, categories: [categories]});
    }
    setSearchParams({});
  }, [])

  return (
    <section className="content margin-header">
      <Split className="container">
        <Adaptive minWidth={1100}>
          <SplitBlock type="fixed" width="350px">
            <SearchFilter filters={filters} setFilters={setFilters} defaultFilters={defaultFilters}/>
          </SplitBlock>
        </Adaptive>
        <SplitBlock type="grow">
          <SearchSort filters={filters} setFilters={setFilters} tips={tips}/>
          <Adaptive maxWidth={1100}>
            <SplitBlock type="fixed" width="350px">
              <SearchFilter filters={filters} setFilters={setFilters} defaultFilters={defaultFilters}/>
            </SplitBlock>
          </Adaptive>
          <Adaptive minWidth={768}>
          {
            filters.search !== "" &&
              <div className="catalog-title">
                <strong>{`Поиск ${filters.search}`}</strong>
                {totalItems !== 0 && <span>●  {totalItems + getNoun(totalItems, " результат", " результата", " результатов")}</span>}
              </div>
          }
          </Adaptive>
          {
            page !== totalPages && items.length !== 0 &&
              <Pagination currentPage={page} totalPages={totalPages} range={5} changePage={page => fetchItems(page, filters)}/>
          }
          {
            items.length !== 0
            ? items.map((item, index) => 
                <ItemPreview key={index} item={item}/>
              )
            : items.length === 0 && !isItemsLoad ? <Alert className="style--alert-negative">По запросу {filters.search !== "" ? filters.search : ""} ничего не найдено</Alert> : <Loader></Loader>
          }
          {
            items.length !== 0 &&
              <Pagination currentPage={page} totalPages={totalPages} range={5} changePage={page => fetchItems(page, filters)}/>
          }
        </SplitBlock>
      </Split>
    </section>
  );
}