import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import MainContext from "./contexts/MainContext";
import AuthContext from "./contexts/AuthContext";
import DataContext from "./contexts/DataContext";

import AuthStore from "./store/AuthStore";
import DataStore from "./store/DataStore";

import { useNotifications } from "./hooks/useNotifications";

import AppRouter from "./routes/AppRouter";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Notifications from "./components/Notifications";

import "./styles/normalize.css";
import "./styles/animate.css";
import "./styles/scrollbar.css";
import "./styles/style.min.css";

export default function App() {
  const authStore = AuthStore();
  const dataStore = DataStore();

  const [notifications, createNotification] = useNotifications();

  useEffect(() => {
    authStore.refresh();
    dataStore.update();
  }, []);

  return (
    <BrowserRouter>
      <MainContext.Provider value={{createNotification}}>
        <AuthContext.Provider value={authStore}>
          <DataContext.Provider value={dataStore}>
            <Header />
            <main className="main">
              <AppRouter />
            </main>
            <Footer />
            <Notifications items={notifications} />
          </DataContext.Provider>
        </AuthContext.Provider>
      </MainContext.Provider>
    </BrowserRouter>
  );
}
