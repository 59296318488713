import React, { useContext } from "react";

import MainContext from "../../contexts/MainContext";

import { useFetching } from "../../hooks/useFetching";

import CatalogService from "../../api/CatalogService";

import Block from "../../components/UI/Block/Block";
import Loader from "../../components/UI/Loader/Loader";
import ItemBuilder from "../ItemBuilder";

export default function CreateItemAdmin({fetchCountPendingItems}) {
  const maincontext = useContext(MainContext);

  const [fetchSeller, isSellerLoad] = useFetching(async () => {
    maincontext.createNotification({
      title: "Статус выгрузки",
      subtitle: "Выгрузка товаров может занять некоторое время...",
      style: "style--block"
    })
    const response = await CatalogService.fetchSeller();
    if (response.data.status) {
      maincontext.createNotification({
        title: "Успешно!",
        subtitle: "Товары с Seller были успешно добавлены",
        style: "style--positive"
      })
      fetchCountPendingItems();
    } else {
      maincontext.createNotification({
        title: "Ошибка",
        subtitle: "При выгрузке c Seller возникла ошибка",
        style: "style--negative"
      })
    }
  });

  return (
    <>
      <Block className="dialog-block">
        <div className="dialog-block dialog-block__column" style={{width: "70%"}}>
          <strong style={{fontSize: "120%", marginBottom: "3px", fontWeight: "500"}}>Вы можете выгрузить товары с Ozon Seller</strong>
          <span style={{fontSize: "95%"}}>Все ваши товары c Ozon будут перенесены или обновлены в базе данных сервиса для дальнейшей работы с ними</span>
        </div>
        <button onClick={() => fetchSeller()} className="style--positive" style={{display: "flex"}}>Выгрузить</button>
      </Block>
      <ItemBuilder onBuild={() => fetchCountPendingItems()}/>
    </>
  );
}
