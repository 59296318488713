import React from "react";

export default function FixedMenu({children, className, ...props}) {

  const rootClasses = ["fixed-menu"];

  if (className) rootClasses.push(className);

  return (
    <div {...props} className={rootClasses.join(' ')}>
      {children}
    </div>
  );
}

export function FixedMenuButton({children, className, ...props}) {
  return (
    <button {...props} className={className}>
      {children}
    </button>
  );
}

