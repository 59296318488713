import React, { useState, useEffect, useContext } from "react";

import DataContext from "../contexts/DataContext";

import Block from './UI/Block/Block';
import Accordion from "./UI/Accordion/Accordion";
import SwitchButton from "./UI/SwitchButton/SwitchButton";
import Select from "./UI/Select/Select";
import Tags from "./UI/Tags/Tags";
import InputList from '../components/UI/InputList/InputList';

export default function SearchFilter({filters, setFilters, defaultFilters}) {

  const dataContext = useContext(DataContext);

  const [localFilters, setLocalFilters] = useState({
    categories: dataContext.categories.data.filter(item => item.id === defaultFilters.categories[0]),
    brands: defaultFilters.brands,
    price: {
      min: 0,
      max: 1000000
    }
  });

  const removeTag = (name) => {setLocalFilters({...localFilters, categories: localFilters.categories.filter(item => item.name !== name)})};
  const resetFilters = () => {setFilters({...defaultFilters, categories: [], brands: []}); setLocalFilters({...defaultFilters, categories: [], brands: []})};
  const changeFilters = () => {setFilters({...filters, ...localFilters, categories: localFilters.categories.map(item => item.id)})};

  function addCategory(item) {
    if (!localFilters.categories.includes(item)) {
      setLocalFilters({...localFilters, categories: [...localFilters.categories, item]});
    }
  }

  function addBrand(state, id) {
    if (state) {
      setLocalFilters({...localFilters, brands: [...localFilters.brands, id]});
    } else {
      let brands = localFilters.brands.filter(brand => brand !== id);
      setLocalFilters({...localFilters, brands: brands})
    }
  }

  useEffect(() => {
    setLocalFilters({...localFilters, price: defaultFilters.price});
  }, [defaultFilters]);

  return (
    <Block className="search-filter">
      <div className="search-filter__search">
        <InputList items={dataContext.categories.data} preview={true} onListClick={addCategory} placeholder="Поиск по категориям"/>
      </div>
      <div className="search-filter__item">
        <Tags tagStyle="style--alert-main-accent" removeable={true} onRemove={removeTag} tags={localFilters.categories}/>
      </div>
      <div className="search-filter__item">
        <Accordion title="Бренды">
          <div className="search-filter__item-content">
            {
              dataContext.brands.data.map((item, index) => 
              <div className="search-filter__row" key={index}>
                <span>{item.name}</span>
                <SwitchButton value={localFilters.brands.includes(item.id)} onChange={state => addBrand(state, item.id)}/>
              </div>
              )
            }
          </div>
        </Accordion>
      </div>
      <div className="search-filter__item">
        <Accordion title="Цена">
          <div className="search-filter__item-content">
            <div className="search-filter__row">
              <input className="search-filter__input"
                value={localFilters.price.min}
                onChange= {e => setLocalFilters({...localFilters, price: {min: e.target.value, max: localFilters.price.max}})}
                type="number"
                min={defaultFilters.price.min} 
                max={defaultFilters.price.max} 
                placeholder={"от " + defaultFilters.price.min}
              />
              <input className="search-filter__input" 
                value={localFilters.price.max}
                onChange= {e => setLocalFilters({...localFilters, price: {min: localFilters.price.min, max: e.target.value}})}
                type="number" 
                min={defaultFilters.price.min} 
                max={defaultFilters.price.max} 
                placeholder={"до " + defaultFilters.price.max}
              />
            </div>
          </div>
        </Accordion>
      </div>
      <div className="search-filter__item">
        <Accordion title="Магазин">
          <div className="search-filter__item-content">
            <Select
              value = {"all"}
              onChange = {value => {}}
              defaultValue="Выбор магазина"
              options={[{name: "Все магазины", value: "all"}]}
            />
          </div>
        </Accordion>
      </div>
      <div className="search-filter__under-btns">
        <button onClick={() => changeFilters()} className="style--main-accent">Применить</button>
        <button onClick={() => resetFilters()} className="style--hover-negative style--negative-lite">Сбросить</button>
      </div>
    </Block>
  );
}