import React, { useState, useRef } from "react";

import { useClickOver } from "../../../hooks/useClickOver";

export default function DropDown({children, className, hoverable=false, ...props}) {

  const [open, setOpen] = useState(false);

  const rootElement = useRef(null);
  const rootClasses = ["dropdown"];
  
  if (className) rootClasses.push(className);
  if (hoverable) rootClasses.push("dropdown--hoverable");
  if (open) rootClasses.push("dropdown--open");

  useClickOver(rootElement, () => {
    setOpen(false);
  });

  return (
    <div ref={rootElement} {...props} onClick={() => setOpen(true)} className={rootClasses.join(' ')}>
      {children}
    </div>
  );
}

export function DropDownContent({children, className, ...props}) {

  const rootClasses = ["dropdown__content"];
  
  if (className) rootClasses.push(className);

  return (
    <div {...props} className={rootClasses.join(' ')}>
      {children}
    </div>
  );
}