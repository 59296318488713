import React from "react";

export default function Breadcrumb({items, ...props}) {
  return (
    <div {...props} className="breadcrumb">
      {
        items.map((item, index) =>
          <div key={index} onClick={item.onClick} className="breadcrumb__item">{item.name}</div>
        )
      }
    </div>
  );
}