import React, { useState, useEffect, useContext } from "react";

import MainContext from "../contexts/MainContext";

import { useFetching } from "../hooks/useFetching";
import CatalogService from "../api/CatalogService";
import CategoryService from "../api/CategoryService";
import BrandService from "../api/BrandService";

import Block, { BlockHeader } from "../components/UI/Block/Block";
import InputList from "../components/UI/InputList/InputList";
import Accordion from "../components/UI/Accordion/Accordion";
import Gallery from "./UI/Gallery/Gallery";
import SwitchButton from "./UI/SwitchButton/SwitchButton";

import TrashIcon from "./icons/TrashIcon";

export default function ItemBuilder({itemdata=undefined, update=false, onBuild=() => {}}) {
  const maincontext = useContext(MainContext);

  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [rawImages, setRawImages] = useState({});

  const defaultItem = {
    name: "",
    about: "",
    brand: {
      id: "",
      name: ""
    },
    category: {
      id: "",
      name: ""
    },
    price: 0,
    status: "pending",
    attachments: {
      tags: [],
      characteristics: []
    },
    seller: {
      indexing: false,
      product_id: 0
    }
  }
  const [item, setItem] = useState(defaultItem);

  const [fetchBrands] = useFetching(async () => {
    const response = await BrandService.getAll();
    if (response.data.status) {
      setBrands(response.data.response);
    }
  });

  const [fetchCategories] = useFetching(async () => {
    const response = await CategoryService.getAll();
    if (response.data.status) {
      setCategories(response.data.response);
    }
  });

  const [fetchSellerItem] = useFetching(async (id) => {
    const response = await CatalogService.getSellerById(id);
    if (response.data.status) {
      maincontext.createNotification({
        title: "Успешно!",
        subtitle: `Товар ${response.data.response.name} найден в системе Seller`,
        style: "style--block",
        buttons: [
          {text: "Загрузить", style: "style--positive", onClick: () => setItem({
            ...item, 
            name: response.data.response.name,
            about: response.data.response.description,
            price: response.data.response.price,
            seller: {
              ...item.seller, 
              images: response.data.response.images
            }
          })
          },
        ]
      })
    } else {
      maincontext.createNotification({
        title: "Ошибка",
        subtitle: `Товар с id ${item.seller.product_id} не найден в системе Seller`,
        style: "style--negative"
      })
    }
  });

  const [createItem] = useFetching(async (data, files, force=false) => {
    const formData = new FormData();
    Array.from(files).forEach((file, index) => {
      formData.append(index, file);
    })

    if (force) data.status = "success";

    if (!update) {
      formData.append("data", JSON.stringify({...data, category: data.category.id, brand: data.brand.id}));
      const response = await CatalogService.create(formData);
      if (response.data.status) {
        maincontext.createNotification({
          title: "Успешно!", 
          subtitle: `Товар "${data.name}" создан`,
          style: "style--positive"
        })
        setItem(defaultItem);
        onBuild();
      } else {
        maincontext.createNotification({
          title: "Ошибка", 
          subtitle: `При создании товара возникла ошибка. Проверьте все поля ввода`,
          style: "style--negative"
        }) 
      }
    } else {
      formData.append("id", data.id);
      delete data.id;
      formData.append("data", JSON.stringify({...data, category: data.category.id, brand: data.brand.id}));
      const response = await CatalogService.update(formData);
      if (response.data.status) {
        maincontext.createNotification({
          title: "Успешно!", 
          subtitle: `Товар "${data.name}" обновлён`,
          style: "style--positive"
        })
        onBuild();
      } else {
        maincontext.createNotification({
          title: "Ошибка", 
          subtitle: `Не удалось обновить товар`,
          style: "style--negative"
        }) 
      }
    }
  });

  function addTag() {
    setItem({...item, attachments: {...item.attachments, tags: [...item.attachments.tags, [{name: ""}]]}})
  }
  function clearTags() {
    setItem({...item, attachments: {...item.attachments, tags: []}})
  }
  function deleteTag(index) {
    let tags = item.attachments.tags;
    tags.splice(index, 1);
    setItem({...item, attachments: {...item.attachments, tags: tags}})
  }
  function updateTag(index, value) {
    let tags = item.attachments.tags;
    tags[index] = {name: value};
    setItem({...item, attachments: {...item.attachments, tags: tags}});
  }
  function addCharacteristicsGroup() {
    setItem({...item, attachments: {...item.attachments, characteristics: [...item.attachments.characteristics, {name: "", items: []}]}})
  }
  function addCharacteristicsGroupItem(index) {
    const group = item.attachments.characteristics[index];
    const characteristics = item.attachments.characteristics;
    group.items.push({name: "", value: ""});
    characteristics[index] = group;
    setItem({...item, attachments: {...item.attachments, characteristics: characteristics}});
  }
  function deleteCharacteristicsGroup(index) {
    const characteristics = item.attachments.characteristics;
    characteristics.splice(index, 1);
    setItem({...item, attachments: {...item.attachments, characteristics: characteristics}});
  }
  function deleteCharacteristicsGroupItem(groupIndex, itemIndex) {
    const characteristics = item.attachments.characteristics;
    characteristics[groupIndex].items.splice(itemIndex, 1);
    setItem({...item, attachments: {...item.attachments, characteristics: characteristics}});
  }
  function updateCharacteristicsGroupName(index, value) {
    const characteristics = item.attachments.characteristics;
    characteristics[index].name = value;
    setItem({...item, attachments: {...item.attachments, characteristics: characteristics}});
  }
  function updateCharacteristicsGroupItemName(groupIndex, itemIndex, value) {
    const characteristics = item.attachments.characteristics;
    characteristics[groupIndex].items[itemIndex].name = value;
    setItem({...item, attachments: {...item.attachments, characteristics: characteristics}});
  }
  function updateCharacteristicsGroupItemValue(groupIndex, itemIndex, value) {
    const characteristics = item.attachments.characteristics;
    characteristics[groupIndex].items[itemIndex].value = value;
    setItem({...item, attachments: {...item.attachments, characteristics: characteristics}});
  }
  function imageConvert(e) {
    const files = Array.from(e.target.files);
    const images = [];
    files.forEach((file) => {
      images.push({url: URL.createObjectURL(file)})
    }) 
    setRawImages(e.target.files);
    setPreviewImages(images);
  }

  useEffect(() => {
    fetchBrands();
    fetchCategories();
    try {
      if (!update) {
        const draftItem = JSON.parse(localStorage.getItem("draft-item"));
        if (JSON.stringify(draftItem) !== JSON.stringify(item) && draftItem) {
          maincontext.createNotification({
            title: "Данные в черновике", 
            subtitle: "У вас есть сохранённый черновик. Восстановить?",
            style: "style--block",
            buttons: [
              {text: "Восстановить", style: "style--positive", onClick: () => setItem(draftItem)},
              {text: "Очистить", style: "style--negative", onClick: () => localStorage.removeItem("draft-item")}
            ]
          })
        }
      }
    } catch {}
  }, [])

  useEffect(() => {
    if (!update) {
      localStorage.setItem("draft-item", JSON.stringify(item));
    }
  }, [item]) 

  useEffect(() => {
    if (itemdata) {
      setItem(itemdata);
      setPreviewImages([]);
    }
  }, [itemdata]);

  return (
    <>
      <Block className="admin">
        <BlockHeader>
          <strong>{update ? "Обновить товар" : "Добавить товар"}</strong>
        </BlockHeader>
        <div className="admin__row">
          <input value={item.name} onChange={e => setItem({...item, name: e.target.value})} className="admin__row-input" placeholder="Имя" type="text" />
        </div>
        <div className="admin__row">
          <textarea value={item.about} placeholder="Описание" onChange={e => setItem({...item, about: e.target.value})} className="admin__row-textarea"></textarea>
        </div>
        <div className="admin__row">
          <InputList className={item.category.id === "root-unknown" ? "style--negative-lite" : ""} value={item.category.id} items={categories} preview={true} onListClick={category => setItem({...item, category})} placeholder="Категория"/>
          <input value={item.category.name} className="admin-table__row-placeholder" type="text" disabled={true}/>
        </div>
        <div className="admin__row">
          <InputList className={item.brand.id === "root-unknown" ? "style--negative-lite" : ""} value={item.brand.id} items={brands} preview={true} onListClick={brand => setItem({...item, brand})} placeholder="Бренд"/>
          <input value={item.brand.name} className="admin-table__row-placeholder" type="text" disabled={true}/>
        </div>
        <div className="admin__row">
          <input value={item.price} onChange={e => setItem({...item, price: e.target.value})} className="admin__row-input" placeholder="Цена" type="number" />
        </div>
        <Accordion title="Изображения">
          <div style={{margin: "15px 0"}}>
            <div className="admin__row">
              <input onChange={e => imageConvert(e)} className="admin__row-input" placeholder="Изображения" accept="image/png,image/jpeg" type="file" multiple={true}/>
              <div style={{width: "400px"}}>
                <Gallery images={previewImages.length === 0 ? item.attachments.images : previewImages}/>
              </div>
            </div>
            {
              item.seller.useImages
              ? item.attachments.images?.length !== 0 && previewImages.length === 0
                ? <div className="style--freeze-lite" style={{textAlign: "center"}}>Используются изображения с Ozon</div>
                : previewImages.length === 0 && <div className="style--negative-lite" style={{textAlign: "center"}}>Добавьте изображения</div>
              : item.attachments.images?.length === 0 && previewImages.length === 0 &&
                <div className="style--negative-lite" style={{textAlign: "center"}}>Добавьте изображения</div>
            }
            {
              !item.seller.useImages &&
              <div className="admin__btns">
                <button onClick={() => {setItem({...item, attachments: {...item.attachments, images: []}, clearImages: true}); setPreviewImages([])}} className="style--negative">Очистить</button>
              </div>
            }
          </div>
        </Accordion>
        <Accordion title="Теги">
          <div style={{margin: "15px 0"}}>
            {
              item.attachments.tags.map((tag, index) => 
              <div key={index} className="admin__row">
                <input value={tag.name || ""} onChange={e => updateTag(index, e.target.value)} className="admin__row-input" placeholder="Имя тега" type="text" />
                <button onClick={() => deleteTag(index)} className="admin__row-button-iconed style--alert-negative">
                  <TrashIcon fill="style--fill-negative"/>
                </button>
              </div>
              )
            }
            <div className="admin__btns">
              <button onClick={() => clearTags()} className="style--negative">Очистить</button>
              <button onClick={() => addTag()} className="style--positive">Добавить тег</button>
            </div>
          </div>
        </Accordion>
        <Accordion title="Характеристики">
          <div style={{margin: "15px 0"}}>
            {
              item.attachments.characteristics.map((group, groupIndex) =>
                <div style={{margin: "15px 0px 40px 0px"}} key={groupIndex}>
                  <div className="admin__row">
                    <input value={group.name} onChange={e => updateCharacteristicsGroupName(groupIndex, e.target.value)} className="admin__row-input" placeholder="Имя" type="text" />
                    <button onClick={() => deleteCharacteristicsGroup(groupIndex)} className="admin__row-button-iconed style--alert-negative">
                      <TrashIcon fill="style--fill-negative"/>
                    </button>
                  </div>
                  {
                    group.items.map((item, itemIndex) =>
                      <div key={itemIndex} style={{margin: "5px 0px 0px 15px"}} className="admin__row">
                        <input value={item.name} onChange={e => updateCharacteristicsGroupItemName(groupIndex, itemIndex, e.target.value)} className="admin__row-input" placeholder="Имя" type="text" />
                        <input value={item.value} onChange={e => updateCharacteristicsGroupItemValue(groupIndex, itemIndex, e.target.value)} className="admin__row-input" placeholder="Значение" type="text" />
                        <button onClick={() => deleteCharacteristicsGroupItem(groupIndex, itemIndex)} className="admin__row-button-iconed style--alert-negative">
                          <TrashIcon fill="style--fill-negative"/>
                        </button>
                      </div>
                    )
                  }
                  <div className="admin__btns">
                    <button onClick={() => addCharacteristicsGroupItem(groupIndex)} className="style--positive">Добавить значение</button>
                  </div>
                </div>
              )
            }
            <div className="admin__btns">
              <button onClick={() => addCharacteristicsGroup()} className="style--positive">Добавить группу</button>
            </div>
          </div>
        </Accordion>
        <Accordion title="Seller интеграция">
          <div style={{margin: "15px 0"}}>
            <div className="admin__row">
              <span style={{marginRight: "10px", width: "280px"}}>Индексировать изменения товара</span>
              <SwitchButton value={item.seller.indexing} onChange={status => setItem({...item, seller: {...item.seller, indexing: status}})}/>
            </div>
            <div className="admin__row">
              <input value={item.seller.product_id} onChange={e => setItem({...item, seller: {...item.seller, product_id: e.target.value}})} className="admin__row-input" placeholder="Seller Id" type="text" />
            </div>
            <div className="admin__btns">
              <button className="style--positive" onClick={() => fetchSellerItem(item.seller.product_id)}>Проверить и загрузить</button>
            </div>
          </div>
        </Accordion>
        <div className="admin__btns">
          {
            update
            ? <button onClick={() => createItem(item, rawImages)} className="style--positive">Обновить</button>
            : <>
                <button onClick={() => createItem(item, rawImages)} className="style--positive">Добавить</button>
                <button onClick={() => createItem(item, rawImages, true)} className="style--positive">Добавить и подтвердить</button>
              </>
          }
        </div>
      </Block>
    </>
  );
}