import React from "react";

import Block from './UI/Block/Block';
import Tags from "./UI/Tags/Tags";
import SearchInput from '../components/SearchInput';
import DropDown, { DropDownContent } from "./UI/DropDown/DropDown";
import SelectMenu from "./UI/SelectMenu/SelectMenu";

export default function SearchSort({filters, setFilters, tips}) {

  const sort = [
    {name: "По убыванию", value: "DESC"},
    {name: "По возрастанию", value: "ASC"}
  ]

  const group = [
    {name: "По бренду", value: "brand"}
  ]

  const changeSort = (item) => setFilters({...filters, sort: item});
  const changeGroup = (item) => setFilters({...filters, group: item});
  const changeSearch = (item) => setFilters({
    ...filters, 
    search: item.meta !== "category" && item.meta !== "brand" ? item.search || item.name : "", 
    categories: item.meta === "category" ? [item.id] : [],
    brands: item.meta === "brand" ? [item.id] : []
  });

  return (
    <Block className="search-sort">
      <div className="search-sort__search">
        <SearchInput title="Поиск по наименованию" onSearch={changeSearch}/>
      </div>
      <div className="search-sort__row search-sort__tags">
        <Tags tagStyle="style--alert-main-accent" onTagClick={changeSearch} clickable={true} tags={tips}/>
      </div>
      <div className="search-sort__row">
        <div className="search-sort__option">
          <span className="search-sort__span">Сортировать</span>
          <DropDown>
            <button className="style--main-accent-lite">{filters.sort.name.toLowerCase()}</button>
            <DropDownContent>
              <Block>
                <SelectMenu value={filters.sort.value} onChange={changeSort} btns={sort}></SelectMenu>
              </Block>
            </DropDownContent>
          </DropDown>
        </div>
        <div className="search-sort__option">
          <span className="search-sort__span">Группировать</span>
          <DropDown>
            <button className="style--main-accent-lite">{filters.group.name.toLowerCase()}</button>
            <DropDownContent>
              <Block>
                <SelectMenu value={filters.group.value} onChange={changeGroup} btns={group}></SelectMenu>
              </Block>
            </DropDownContent>
          </DropDown>
        </div>
      </div>
    </Block>
  );
}