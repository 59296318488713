import React from "react";
import { Link } from "react-router-dom";

import Tags from "./UI/Tags/Tags";

export default function ItemPreviewCompact({item}) {
  return (
    <Link to={"/catalog/" + item.identifier} className="item-preview-compact">
      <div className="item-preview-compact__image">
        <img src={item?.attachments?.images[0].url} alt="" />
      </div>
      <span className="item-preview-compact__name">{item?.name}</span>
      <div className="item-preview-compact__tags">
        <Tags tagStyle="style--alert-main-accent" tags={item?.attachments?.tags}/>
      </div>
      <div className="item-preview-compact__price">
        <span>{item?.price} ₽</span>
        <button className="style--freeze-lite">Подробнее</button>
      </div>
    </Link>
  );
}