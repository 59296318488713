import React, { useContext } from "react";
import { Link } from "react-router-dom";

import DataContext from "../contexts/DataContext";

import Block from "./UI/Block/Block";
import Loader from "./UI/Loader/Loader";

import BigArrowIcon from "./icons/BigArrowIcon"

export default function AboutBrands() {

  const dataContext = useContext(DataContext);

  return (
    <div className="brand-list" style={{marginTop: "20px"}}>
      {
        dataContext.brands.isLoad
        ? dataContext.brands.data.map((brand, index) =>
            <Block key={index} className="brand-list__item">
              <Link to={`/catalog?brands=${brand.id}`}>
                <img src={brand.icon} alt="" />
              </Link>
            </Block>
          )
        : <Loader></Loader>
      }
      <Block className="brand-list__button">
        <Link to="/categories">
          <span>Все бренды</span>
          <BigArrowIcon/>
        </Link>
      </Block>
    </div>
  );
}