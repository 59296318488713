import React from "react";

export default function Avatar({type, content, size, fontSize = "100%"}) {
  return (
    <div className="avatar" style={{width: size, height: size}}>
      {
        type === "image"
        ? <img src={content} alt="" />
        : <strong style={{fontSize}}>{content}</strong>
      }
    </div>
  );
}