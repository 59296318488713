import React, { useState, useEffect } from "react";

import { useFetching } from "../../hooks/useFetching";

import CatalogService from "../../api/CatalogService";

import AuditItem from "../AuditItem";
import Alert from "../UI/Alert/Alert";

export default function AuditAdmin() {

  const [events, setEvents] =  useState([]);

  const [fetchEvents] = useFetching(async () => {
    const response = await CatalogService.getEvents();
    if (response.data.status) {
      setEvents(response.data.response);
    }
  });

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <>
    {
      events.length !== 0
      ? events.map((item, index) =>
          <AuditItem key={index} item={item}/>
        )
      : <Alert className="style--alert-negative">Нет записанных событий</Alert>
    }
    </>
  )
}