import React, { useState, useEffect } from "react";

export default function Notifications({items}) {
  return (  
    <div className="notifications">
      {
        items.map((item, index) => 
          <Notification key={index} item={item}></Notification>
        )
      }
    </div> 
  );
}

function Notification({item}) {

  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, 8100);
  });

  if (!visible) return;

  return (
    <div className={`notification ${item.style !== undefined  ? item.style : ""}`}>
      <button className="notification__close-btn" onClick={() => setVisible(false)}>Закрыть</button>
      {item.title && <strong>{item.title}</strong>}
      {item.subtitle && <span>{item.subtitle}</span>}
      {item.image && <img src={item.image} alt="" />}
      {
        item?.buttons?.map((button, index) => 
          <button key={index} className={button.style} onClick={() => {button.onClick(); setVisible(false)}}>{button.text}</button>
        ) 
      }
    </div>
  );
}