import React, { useState, useEffect } from "react";
import hljs from "highlight.js";

export default function Code({children, lang, ...props}) {

  const [code, setCode] = useState("");

  useEffect(() => {
    setCode(hljs.highlight(children, {language: lang}).value);
  }, []);

  return (
    <pre {...props}>
      <code className={`${lang} hljs`} dangerouslySetInnerHTML={{__html: code}}></code>
    </pre>
  );
}