import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import DataContext from "../contexts/DataContext";

import { useFetching } from "../hooks/useFetching";
import CatalogService from "../api/CatalogService";

import { addCategory } from "../utils/LastCategories";

import InputList from "../components/UI/InputList/InputList";

import SearchIcon from "./icons/SearchIcon";

export default function SearchInput({items=[], placeholder="Поиск", onSearch}) {

  const dataContext = useContext(DataContext);

  const [search, setSearch] = useState("");
  const [tips, setTips] = useState(items);
  const [searchItems, setSearchItems] = useState([]);
  const timer = useRef(null);

  const router = useNavigate();

  const [fetchTips] = useFetching(async (search) => {
    const response = await CatalogService.getTips({search});
    if (response.data.status) {
      setTips(response.data.response.map((item) => {return {...item, about: item.category.name, icon: item.category.icon}}));
    }
  })

  function searchRedirect(item) {
    if (item.search !== "" || item.name) {
      if (onSearch) {
        if (item.meta === "category") {
          addCategory(item.id);
        }
        onSearch(item)
      } else {
        if (item.meta === "category") {
          router(`/catalog?categories=${item.id}`);
          addCategory(item.id);
        } else if (item.meta === "brand") {
          router(`/catalog?brands=${item.id}`);
        } else {
          router(`/catalog?search=${item.search || item.name}`);
        }
      }
    }
  }

  function keyHandler(e) {
    if (e.key === "Enter") searchRedirect({search});
  }

  useEffect(() => {
    clearInterval(timer.current);
    timer.current = setTimeout(() => {
      fetchTips(search);
    }, 500)
    return () => {
      clearInterval(timer.current);
    }
  }, [search]);

  useEffect(() => {
    setSearchItems([
      ...tips, 
      ...dataContext.categories.data.map((item) => {return {...item, about: "Категория", meta: "category"}}),
      ...dataContext.brands.data.map((item) => {return {...item, about: "Бренд", meta: "brand"}})
    ]);
  }, [tips]);

  return (
    <div className="search-input" onKeyDown={e => keyHandler(e)}>
      <InputList listIcon={<SearchIcon/>} items={searchItems} onChange={setSearch} onListClick={searchRedirect} placeholder={placeholder}/>
      <div className="search-input__tool">
        <button onClick={() => searchRedirect({search})} className="search-input__btn">
          <SearchIcon fill="style--fill-freeze"/>
        </button>
      </div>
    </div>
  );
}