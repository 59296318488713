import React, { useContext, useState } from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";

import DataContext from "../contexts/DataContext";

import Block from "./UI/Block/Block";
import Loader from "./UI/Loader/Loader";

export default function AboutShops() {
  const dataContext = useContext(DataContext);

  const [center, setCenter] = useState([dataContext.shops.data[0]?.geometry?.latitude || 44.041552, dataContext.shops.data[0]?.geometry?.longitude || 43.046541]);

  return (
      <Block className="shops">
        <YMaps>
          <Map className="shops__map" state={{center: center, zoom: 12}}>
          {
            dataContext.shops.data.map((item, index) => 
              <Placemark key={index} geometry={[item.geometry.latitude, item.geometry.longitude]} properties={{iconCaption: item.name}}/>
            )
          }
          </Map>
        </YMaps>
        <div className="shops__list">
          <strong className="shops__list-title">Магазины в г.Пятигорске</strong>
          {
            dataContext.shops.isLoad
            ? dataContext.shops.data.map((item, index) => 
              <div key={index} className="shops__shop">
                <strong onClick={() => setCenter([item.geometry.latitude, item.geometry.longitude])} className="shops__shop-name">«{item.name}»</strong>
                <span className="shops__shop-subtitle style--freeze-lite">{item.geometry.adress}, {item.phone}</span>
                <span className="shops__shop-subtitle">ПН-ПТ: {item.worktime.weekdays}, СБ: {item.worktime.weekends}, ВС: Выходной</span>
              </div>
              )
            : <Loader></Loader>
          }
        </div>
      </Block>
  );
}
