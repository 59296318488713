import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import DataContext from "../contexts/DataContext";

import Block from "../components/UI/Block/Block";
import FixedMenu, { FixedMenuButton } from '../components/UI/FixedMenu/FixedMenu';
import Loader from "../components/UI/Loader/Loader";
import Alert from "../components/UI/Alert/Alert";

import CategoryIcon from "../components/icons/CategoryIcon";

export default function Categories() {

  const router = useNavigate();
  const dataContext = useContext(DataContext);

  return (
    <Block className="about-categories" style={{height: "560px"}}>
      <FixedMenu style={{maxHeight: "550px", overflow: "auto"}}>
        {
          !dataContext.categories.error 
          ? dataContext.categories.isLoad 
            ? dataContext.categories.data.map((item, index) => 
            <FixedMenuButton key={index} onClick={() => router(`/catalog?categories=${item.id}`)} className="style--hover-main-accent">
              <img src={item.icon} alt="" />
              <span>{item.name}</span>
            </FixedMenuButton>
            )
            : <Loader/>
          : <Alert>Произошла ошибка</Alert>
        }
      </FixedMenu>
      <FixedMenu>
        <hr />
        <FixedMenuButton onClick={() => router("/categories")} className="style--hover-second-accent">
          <CategoryIcon />
          <span>Все категории</span>
        </FixedMenuButton>
      </FixedMenu>
    </Block>
  )
}