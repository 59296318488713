import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import AuthContext from "../contexts/AuthContext";

import NotFound from "../pages/NotFound";

import { publicPaths, privatePaths, adminPaths } from "./paths";

export default function AppRouter() {
  const authContext = useContext(AuthContext);

  return (
    <Routes>
      {
        publicPaths.map((item, index) =>
          <Route key={index} exact={item.exact} path={item.path} element={<item.component title={item.title}/>}/>
        )
      }
      {
        authContext.isAuth &&
        privatePaths.map((item, index) =>
          <Route key={index} exact={item.exact} path={item.path} element={<item.component title={item.title}/>}/>
        )
      }
      {
        authContext.isAuth && authContext.user.isAdmin &&
        adminPaths.map((item, index) =>
          <Route key={index} exact={item.exact} path={item.path} element={<item.component title={item.title}/>}/>
        )
      }
      <Route path="*" element={<NotFound />}/>
    </Routes>
  );
}