import React, { useState, useEffect } from "react";

export default function Adaptive({children, minWidth=0, maxWidth=10000}) {
  const [show, setShow] = useState(false);

  const handleResize = () => {setShow(window.innerWidth >= minWidth && window.innerWidth <= maxWidth)};

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  return (
    <>
      {
        show && children
      }
    </>
  );
}