import React from 'react';

export default function Banner({children, height=null, blur=null, image}) {
  return (
    <div className="banner" style={height ? {height:height} : null}>
      <img className="banner__image" src={image} alt=""/>
      <div className="banner__content" style={blur ? {backdropFilter: `blur(${blur}px)`}: null}>
        {children}
      </div>
    </div>
  );
}