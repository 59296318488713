import React, { useEffect, useState } from 'react';

import MetaService from '../api/MetaService';
import { useFetching } from '../hooks/useFetching';
import { usePage } from '../hooks/usePage';

import Split, { SplitBlock } from '../components/UI/Split/Split';
import Block from '../components/UI/Block/Block';
import Loader from '../components/UI/Loader/Loader';

export default function Legal({title}) {
  usePage(title, true);

  const [policy, setPolicy] = useState("");

  const [fetchPolicy, isPolicyLoad] = useFetching(async () => {
    const response = await MetaService.getPolicy();
    setPolicy(response.data);
  })

  useEffect(() => {fetchPolicy()}, []);

  return (
    <section className="content margin-header">
      <Split className="container" m_reverse={true}>
        <SplitBlock type="grow">
          {
            isPolicyLoad
            ? <Loader></Loader>
            : <Block className="doc">
                <div className="doc__title">
                  <strong>Политика конфиденциальности</strong>
                </div>
                <div className="dov__content">
                  <pre>{policy}</pre>
                </div>
              </Block>
          }
        </SplitBlock>
        <SplitBlock type="fixed" width="350px"></SplitBlock>
      </Split>
    </section>
  );
}