import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

import { usePage } from "../hooks/usePage";
import { useFetching } from "../hooks/useFetching";

import CatalogService from "../api/CatalogService";

import { addToFavorites, checkFavorites } from "../utils/Favorites";

import ItemPreviewCompact from '../components/ItemPreviewCompact';
import HorizontalScroll from '../components/UI/HorizontalScroll/HorizontalScroll';
import Split, { SplitBlock } from "../components/UI/Split/Split";
import Block, { BlockHeader, BlockNav, BlockMenu } from "../components/UI/Block/Block";
import Loader from "../components/UI/Loader/Loader";
import FixedMenu, { FixedMenuButton } from "../components/UI/FixedMenu/FixedMenu";
import Modal from '../components/UI/Modal/Modal';
import ShopsPreviewModal from "../components/ShopsPreviewModal";
import Gallery from "../components/UI/Gallery/Gallery";
import Table from "../components/UI/Table/Table";
import Tags from "../components/UI/Tags/Tags";
import Tooltip from "../components/UI/Tooltip/Tooltip";
import Breadcrumb from "../components/UI/Breadcrumb/Breadcrumb";
import Adaptive from "../components/UI/Adaptive/Adaptive";

import HeartFillIcon from "../components/icons/HeartFillIcon";
import CategoryIcon from "../components/icons/CategoryIcon";
import SmallArrowIcon from "../components/icons/SmallArrowIcon";
import InfoIcon from "../components/icons/InfoIcon";

export default function ItemPage({title}) {
  const [inFavorites, setInFavorites] = useState(false); 

  usePage(title, true);

  const params = useParams();
  const router = useNavigate();

  const [item, setItem] = useState([]);
  const [tips, setTips] = useState([]);
  const [topItems, setTopItems] = useState([]);
  const [shopsPreview, setShopsPreview] = useState(false);
  const [priceWarning, setPriceWarning] = useState(false);
  const [chapter, setChapter] = useState("about");

  const [fetchItem, isItemLoad] = useFetching(async () => {
    const response = await CatalogService.getByIdentifier(params.identifier);
    if (response.data.status === true) {
      setItem(response.data.response);
      document.title = response.data.response.name + " - Мир техники";
      fetchCatalog(response.data.response.category.id);
      fetchTips(response.data.response.name, response.data.response.brand.id);
    } else {
      router("/not-found");
    }
  });

  const [fetchTips] = useFetching(async (name, brand) => {
    const response = await CatalogService.getTips({brand});
    if (response.data.status) {
      setTips(response.data.response.filter(tip => tip.name !== name));
    }
  }); 

  const [fetchCatalog] = useFetching(async (category) => {
    const response = await CatalogService.getPage(0, {
      search: "",
      categories: [category],
      sort: {value: "asc"},
      group: {value: "brand"},
      price: {
        min: 0,
        max: 1000000
      }
    })
    if (response.data.status) {
      setTopItems(response.data.response.items);
    }
  });

  useEffect(() => {
    fetchItem();
    window.scrollTo(0, 0);
  }, [params.identifier]);

  useEffect(() => {
    setInFavorites(checkFavorites(item.id));
  }, [item]);

  // TODO refactor item page
  return (
    <section className="content container margin-header">
      <Adaptive minWidth={768}>
        <Breadcrumb style={{marginBottom: "10px"}} items={[
          {name: "Каталог", onClick: () => router('/catalog')},
          {name: item.brand?.name, onClick: () => router(`/catalog?brands=${item.brand?.id}`)},
          {name: item.category?.name, onClick: () => router(`/catalog?brands=${item.brand?.id}&categories=${item.category?.id}`)},
          {name: item?.name, onClick: () => {}}
        ]}/>
      </Adaptive>
      <Block className="item">
        <div className="item__block">
          <Gallery images={item?.attachments?.images}/>
        </div>
        <div className="item__block">
          <strong>{item.name}</strong>
          <Tags tags={item?.attachments?.tags} tagStyle="style--alert-main-accent"/>
          <div className="item-buy inner-block">
            <div className="item-buy__row">
              <div className="item-buy__header">
                {
                  item?.seller?.indexing
                  ? <span className="item-buy__header-price">{item.price} ₽</span>
                  : <span className="item-buy__header-price style--negative-lite">≈ {item.price} ₽</span>
                }
                {
                  !item.seller?.indexing &&
                  <Tooltip content={"Цена на этот товар может отличаться в магазине"}>
                    <button onClick={() => setPriceWarning(true)} style={{zoom: "0.7", borderRadius: "100px", padding: "7px", display: "flex"}} className="style--block">
                      <InfoIcon fill="style--fill-negative"/>
                    </button>
                  </Tooltip>
                }
                {
                  item.price > 5000 && item.seller?.indexing &&
                  <span className="style--freeze-lite">Доступно в кредит</span>
                }
              </div>
              <div className="item-buy__btns">
                {
                  inFavorites
                  ? <Tooltip content="Убрать из избранного">
                      <button onClick={() => {addToFavorites(item.id); setInFavorites(!inFavorites)}} className="item-buy__favorites style--alert-main-accent style--fill-main-accent">
                        <HeartFillIcon/>
                      </button>
                    </Tooltip>
                  : <Tooltip content="Добавить в избранное">
                      <button onClick={() => {addToFavorites(item.id); setInFavorites(!inFavorites)}} className="item-buy__favorites style--freeze style--fill-freeze">
                        <HeartFillIcon/>
                      </button>
                    </Tooltip>
                }
                <button className="item-buy__btn style--main-accent" onClick={() => setShopsPreview(true)}>Купить</button>
              </div>
            </div>
            <div className="item-buy__row">
              {
                !item.seller?.indexing 
                ? <span style={{fontSize: "90%"}}>Наличие уточняйте в магазине или по телефону</span>
                : item.seller?.stocks?.present === 0
                  ? <span className="style--alert-negative" style={{padding: "5px 15px", borderRadius: "7px"}}>Нет в наличии</span>
                  : <span className="style--alert-positive" style={{padding: "5px 15px", borderRadius: "7px"}}>В наличии: {item.seller?.stocks?.present}</span>
                  
              }
            </div>
          </div>
          <button className="item-brand" onClick={() => router(`/catalog?brands=${item.brand?.id}`)}>
            <div className="item-brand__title">
              <strong>{item.brand?.name}</strong>
              <span>Перейти к бренду</span>
            </div>
            <img src={item.brand?.icon} alt="" />
          </button>
        </div>
      </Block>
      <Split>
        <SplitBlock type="grow">
          <Block>
            <BlockMenu>
              <button className={chapter === "about" ? "style--main-accent-lite" : ""} onClick={() => setChapter("about")}>Описание</button>
              <button className={chapter === "characteristics" ? "style--main-accent-lite" : ""} onClick={() => setChapter("characteristics")}>Характеристики</button>
            </BlockMenu>
            {
              chapter === "about" &&
                <div dangerouslySetInnerHTML={{__html: item?.about}}></div>
            }
            {
              chapter === "characteristics" &&
              <>
                {
                  item?.attachments?.characteristics.length !== 0
                  ? <Table items={item?.attachments?.characteristics}/>
                  : <span>Характеристики для этого товара не найдены</span>
                }
              </>
            }
          </Block>
          <Block>
            <BlockNav>
              <div>
                <CategoryIcon/>
                <strong>Похожие товары</strong>
              </div>
              <div>
                <Link to={`/catalog?categories=${item?.category?.id}`}>Показать все</Link>
                <SmallArrowIcon fill="style--fill-main-accent" rotate="270deg"/>
              </div>
            </BlockNav>
            <HorizontalScroll step={300}>
              {
                topItems.map((item, index) => 
                  <ItemPreviewCompact key={index} item={item}/>
                )
              }
            </HorizontalScroll>
          </Block>
        </SplitBlock>
        <SplitBlock type="fixed" width="350px">
          <Block className="category-preview">
            <div className="category-preview__content">
              <img style={{width: "45px", height: "45px"}} src={item?.category?.icon} alt="" />
              <span>{item?.category?.name}</span>
            </div>
            <button className="style--hover-positive" onClick={() => router(`/catalog?categories=${item?.category?.id}`)}>Перейти</button>
            </Block>
            {
              isItemLoad
              ? <Loader></Loader>
              : tips.length !== 0 && 
                <Block>
                  <BlockHeader>
                    <strong style={{marginLeft: "10px", fontSize: "110%"}}>Товары этого бренда</strong>
                  </BlockHeader>
                  <FixedMenu>
                    {
                      tips.map((tip, index) => 
                        <FixedMenuButton key={index} onClick={() => router(`/catalog/${tip.identifier}`)} className="style--hover-main-accent">
                          <img style={{width: '35px', height: '35px'}} src={tip.image.url} alt="" />
                          <span>{tip.name}</span>
                        </FixedMenuButton>   
                      )
                    }
                  </FixedMenu>
                </Block>
            }
        </SplitBlock>
      </Split>
      <Modal visible={shopsPreview} setVisible={setShopsPreview}>
        <ShopsPreviewModal itemLink={item.seller?.link}/>
      </Modal>
      <Modal visible={priceWarning} setVisible={setPriceWarning}>
        {
        // TODO create ModalNotification component 
        } 
        <Block className="modal-notification">
          <img src={`${process.env.REACT_APP_URL}/images/astonishment.jpg`} alt=""/>
          <div className="modal-notification__content">
            <strong>Внимание!</strong>
            <p>
              <span>Цена на этот товар может отличаться от реальной. Уточните цену в магазине или позвоните</span>
              <a href="tel:+79286332220">+7 (928) 633-22-20</a>
            </p>
            <div className="modal-notification__btns">
              <button className="style--second-accent" onClick={() => setPriceWarning(false)}>Понятно</button>
            </div>
          </div>
        </Block>
      </Modal>
    </section>
  );
}