import React from "react";

export default function Select({options, defaultValue, value, onChange, className}) {

  const rootClasses = ["select"];

  if (className) rootClasses.push(className)

  return (
    <select className={rootClasses.join(' ')} value={value} onChange={e => onChange(e.target.value)}>
      <option disabled value="">{defaultValue}</option>
      {
        options.map(option =>
          <option key={option.value} value={option.value}>{option.name}</option>
        )
      }
    </select>
  );
}