import instance from "./AxiosInstance";

export default class BrandService {
  static async getAll() {
    return await instance.get(`/brand`);
  }
  static async create(data) {
    return await instance.post(`/brand/create`, data);
  }
  static async updateIcon(formData) {
    return await instance.put(`/brand/update/icon`, formData);
  }
  static async delete(id) {
    return await instance.delete(`/brand/delete`, {data: {id}});
  }
}