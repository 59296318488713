export function addCategory(id) {
  try {
    const categories = JSON.parse(localStorage.getItem("lastCategories"));
    if (!categories.includes(id)) {
      categories.push(id);
      localStorage.setItem("lastCategories", JSON.stringify(categories));
    }
  } catch {
    localStorage.setItem("lastCategories", JSON.stringify([]));
  }
}

export function getCategories() {
  try {
    return JSON.parse(localStorage.getItem("lastCategories"));
  } catch {
    return []
  }
}