import React from "react";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 

export default function DefaultCarousel({children, interval=7000, autoPlay=false}) {
  return (
    <Carousel 
      showThumbs={false} 
      interval={interval} 
      autoPlay={autoPlay} 
      emulateTouch={true} 
      infiniteLoop={true} 
      showStatus={false}>
      {children}
    </Carousel>
  );
}

