import React from "react";

import TrashIcon from "../../icons/TrashIcon";

export default function Tags({tags=[], tagStyle=null, onRemove, onTagClick, removeable=false, clickable=false}) {
  return (
    <div className="tags">
      {
        tags.map((item, index) => 
        <div key={index} className={tagStyle ? "tags__tag " + tagStyle : "tags__tag"} style={clickable ? {cursor: "pointer"} : null}>
          <button onClick={() => item.onClick && clickable ? item.onClick(item) : onTagClick && clickable && onTagClick(item)} className="tags__btn">
            <span>{item.name}</span>
          </button>
          {
            removeable &&
            <button onClick={() => onRemove(item.name)} className="tags__btn-remove style--hover-negative style--fill-main-accent">
              <TrashIcon/>
            </button>
          }
        </div>
        )
      }
    </div>
  );
}
