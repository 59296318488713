export const PaginationArray = (currentPage, totalPages, range) => {
  let result = [];
  let added = 0;
  for (let page = 1; page <= totalPages; page++) {
    if (added < range) {
      if (page >= currentPage - range / 2) {
        result.push(page);
        added += 1;
      }
    } else {
      break;
    }
  }
  return result;
}