import React from "react";

import CheckedIcon from "../../icons/CheckedIcon";
import UncheckedIcon from "../../icons/UncheckedIcon";

export default function SelectMenu({btns=[], value, onChange}) {
  return (
    <div className="select-menu">
      {
        btns.map((item, index) => 
        <button key={index} className="style--hover-main-accent" onClick={() => onChange(item)}>
          {
            value === item.value
            ? <CheckedIcon fill="style--fill-main-accent"/>
            : <UncheckedIcon fill="style--fill-text" />
          }
          <span>{item.name}</span>
        </button>
        )
      }
    </div>
  );
}

