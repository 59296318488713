import React, { useContext } from "react";
import { Link } from "react-router-dom";

import DataContext from "../contexts/DataContext";

import { usePage } from "../hooks/usePage";

import Split, { SplitBlock } from "../components/UI/Split/Split";
// import Breadcrumb from "../components/UI/Breadcrumb/Breadcrumb";

export default function Categories({title}) {
  usePage(title, true);

  const dataContext = useContext(DataContext);

  // fix nested categories
  // fix breadcrumbs

  // const router = useNavigate();

  // const [categories, setCategories] = useState(dataContext.categories.data.filter(item => !item.parentId));
  // const [categoryParents, setCategoryParents] = useState([
  //   {name: "Все категории", onClick: () => {
  //     setCategories(dataContext.categories.data.filter(item => !item.parentId));
  //     setCategoryParents([categoryParents[0]]);
  //   }}
  // ]);

  // function changeCategoriesNode(category) {
  //   const newCategories = dataContext.categories.data.filter(item => item.parentId === category.id);

  //   if (Object.keys(newCategories).length === 0) {
  //     router(`/catalog?categories=${category.id}`);
  //   } else {
  //     setCategories(newCategories);
  //     setCategoryParents([
  //       ...categoryParents,
  //       {...category, onClick: () => {}}
  //     ])
  //   }
  // }

  // useEffect(() => {
  //   setCategories(dataContext.categories.data.filter(item => !item.parentId));
  //   setCategoryParents([
  //     {name: "Все категории", onClick: () => {
  //       setCategories(dataContext.categories.data.filter(item => !item.parentId));
  //       setCategoryParents([categoryParents[0]]);
  //     }}
  //   ])
  // }, [dataContext.categories]);

  return (
    <section className="content margin-header">
      <Split className="container">
        <SplitBlock type="grow">
          {/* <Breadcrumb style={{marginBottom: "15px"}} items={categoryParents} /> */}
          <div className="categories">
            {/* {
              categories.map((item, index) => 
                <button key={index} className="categories__item" onClick={() => changeCategoriesNode(item)}>
                  <img src={item.icon} alt="" />
                  <span>{item.name}</span>
                </button>
              )
            } */}
            {
              dataContext.categories.data.map((item, index) => 
                <Link key={index} className="categories__item" to={`/catalog?categories=${item.id}`}>
                  <img src={item.icon} alt="" />
                  <span>{item.name}</span>
                </Link>
              )
            }
            {
              dataContext.brands.data.map((item, index) => 
                <Link key={index} className="categories__item" to={`/catalog?brands=${item.id}`}>
                  <img src={item.icon} alt="" />
                  <span>{item.name}</span>
                </Link>
              )
            }
          </div>
        </SplitBlock>
      </Split>
    </section>
  );
}
