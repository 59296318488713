import React from "react";

import Block from "./UI/Block/Block";

export default function AboutService() {
  return (
    <Block className="service" style={{minWidth: "50vw", minHeight: "auto"}}>
      <div className="service__block">
        <img src="https://static.tildacdn.com/tild3932-6534-4630-b363-383038313962/y_vasi-kropotkin_sti.png" alt="" />
      </div>
      <div className="service__block">
        <strong>ГАРАНТИЯ И СЕРВИС</strong>
        <span>На всю приобретаемую у нас технику STIHL действует гарантия 1 год от производителя. Наш сервисный центр предлагает все виды сервисных услуг.</span>
        <ul>
          <li>Авторизованный сервисный центр</li>
          <li>Механики, прошедшие подготовку STIHL</li>
          <li>Гарантийный ремонт</li>
          <li>Постгарантийный ремонт</li>
          <li>Срочный ремонт техники</li>
          <li>Только оригинальные запчасти</li>
        </ul>
      </div>
    </Block>
  );
}
