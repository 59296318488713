import React from "react";

export default function Alert({children, className, ...props}) {

  const rootClasses = ["alert"];
  if (className) rootClasses.push(className);

  return <div {...props} className={rootClasses.join(" ")}>{children}</div>
}

