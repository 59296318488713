import React, { useEffect } from "react";

export default function Modal({children, visible, setVisible, onClose = () => {}}) {

  const rootClasses = ["modal"];

  if (visible === true) rootClasses.push("modal--active");

  useEffect(() => {
    if (visible) document.body.style.overflow = "hidden";
    else document.body.style.removeProperty("overflow");
  }, [visible]);

  return (
    <div className={rootClasses.join(' ')} onClick={() => {setVisible(false); onClose()}}>
      <div className="modal__content" onClick={e => {e.stopPropagation()}}>
        {children}
      </div>
    </div>
  );
}