export function addToFavorites(id) {
  const items = JSON.parse(localStorage.getItem("favorites"));
  if (items) {
    if (!items.includes(id)) {
      localStorage.setItem("favorites", JSON.stringify([...items, id]));
    } else {
      localStorage.setItem("favorites", JSON.stringify(items.filter(item => item !== id)));
    }
  } else {
    localStorage.setItem("favorites", JSON.stringify([id]));
    return true;
  }
}

export function removeFavoriteById(id) {
  localStorage.setItem("favorites",
    JSON.stringify(
      JSON.parse(localStorage.getItem("favorites").filter(item => item !== id))
    )
  )
}

export function checkFavorites(id) {
  const items = JSON.parse(localStorage.getItem("favorites"));
  if (items) {
    if (items.includes(id)) return true;
  }
  return false;
}