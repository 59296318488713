import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import AuthContext from "../contexts/AuthContext";

import { usePage } from "../hooks/usePage";
import Split, { SplitBlock } from "../components/UI/Split/Split"
import Block from "../components/UI/Block/Block";
import FixedMenu, { FixedMenuButton } from "../components/UI/FixedMenu/FixedMenu";

import LogoutIcon from "../components/icons/LogoutIcon";
import DoneIcon from "../components/icons/DoneIcon";
import ShieldIcon from "../components/icons/ShieldIcon";
import HeartIcon from "../components/icons/HeartIcon";
import StorageIcon from "../components/icons/StorageIcon";

export default function Account({title}) {
  usePage(title, true);

  const authcontext = useContext(AuthContext);
  const router = useNavigate();

  return (
    <section className="content container margin-header">
      <Split>
        <SplitBlock type="fixed" width="350px">
          <Block className="usercard">
            <div className="usercard__avatar">
              {
                authcontext.user.photo !== ""
                ? <img src={authcontext.user.photo} alt="" />
                : <strong>{authcontext.user.login}</strong>
              }
            </div>
            <FixedMenu>
              <FixedMenuButton className="style--negative" onClick={() => {authcontext.logout(); router("/")}}>
                <LogoutIcon fill="style--fill-white"/>
                <span>Выйти</span>
              </FixedMenuButton>
            </FixedMenu>
          </Block>
        </SplitBlock>
        <SplitBlock type="grow">
          <Block className="userinfo">
            <div className="userinfo__row">
              <strong className="userinfo__login">{authcontext.user.login}</strong>
              {
                authcontext.user.isActivated &&
                <div className="userinfo__status">
                  <ShieldIcon fill="style--fill-positive"/>
                  <span className="style--positive-lite">Аккаунт подтверждён</span>
                </div>
              }
              {
                authcontext.user.isAdmin &&
                <div className="userinfo__status">
                  <DoneIcon fill="style--fill-second-accent"/>
                  <span className="style--second-accent-lite">Администрация сайта</span>
                </div>
              }
            </div>
            <span>{authcontext.user.firstName} {authcontext.user.lastName} | {authcontext.user.email}</span>
            <div className="inner-block userinfo__nav">
              <button className="style--hover-main-accent" onClick={() => router('/favorites')}>
                <HeartIcon />
                <span>Избранное</span>
              </button>
              {
                authcontext.user.isAdmin &&
                <button className="style--hover-main-accent" onClick={() => router('/admin')}>
                  <StorageIcon /> 
                  <span>Управлять сайтом</span>
                </button>
              }
            </div>
          </Block>
        </SplitBlock>
      </Split>
    </section>
  );
}