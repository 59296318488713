import React, { useState, useRef, useEffect } from "react";

import SmallArrowIcon from "../../icons/SmallArrowIcon";

export default function HorizontalScroll({children, step}) {

  const contentRef = useRef(null);

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    if (contentRef?.current?.scrollWidth !== contentRef?.current?.offsetWidth) {
      setScroll(true);
    }
  }, [children]);

  function scrollTarget(e) {
    if (e.targetTouches[0].clientX > e.view.outerWidth / 2) {
      contentRef.current.scrollLeft += step
    } else {
      contentRef.current.scrollLeft -= step
    }
  }

  return (
    <div className="horizontal-scroll">
      {
        scroll &&
        <>
          <button className="horizontal-scroll__button horizontal-scroll__left style--hover-main-accent" onClick={() => {contentRef.current.scrollLeft -= step}}>
            <SmallArrowIcon rotate="90deg"></SmallArrowIcon>
          </button>
          <button className="horizontal-scroll__button horizontal-scroll__right style--hover-main-accent" onClick={() => {contentRef.current.scrollLeft += step}}>
            <SmallArrowIcon rotate="270deg"></SmallArrowIcon> 
          </button>
        </>
      }
      <div ref={contentRef} onTouchStart={e => scrollTarget(e)} className="horizontal-scroll__content">
        {children}
      </div>
    </div>
  );
}