import React from 'react';

export default function Tooltip({children, content}) {
  return(
    <div className="tooltip">
      <div className="tooltip__content">
        {content}
      </div>
      <div className="tooltip__arrow"></div>
      {children}
    </div>
  );
}