import React from "react";

export default function Split({children, className, m_reverse}) {

  const rootClasses = ["split"];

  if (className) rootClasses.push(className);
  if (m_reverse) rootClasses.push("split--reverse");

  return(
    <div className={rootClasses.join(" ")}>
      {children}
    </div>
  );
}

export function SplitBlock({children, type, width}) {

  const rootClasses = ["split__page"];

  if (type === "grow") {
    rootClasses.push("split--grow");
  } else if (type === "fixed") {
    rootClasses.push("split--fixed");
  }

  return(
    <div className={rootClasses.join(" ")} style={width ? {width: width} : null}>
      {children}
    </div>
  );
}