import React, { useEffect, useState } from "react";

import CatalogService from "../api/CatalogService";

import { usePage } from "../hooks/usePage";
import { useFetching } from "../hooks/useFetching";

import Alert from "../components/UI/Alert/Alert";
import Split, { SplitBlock } from "../components/UI/Split/Split";
import Pagination from "../components/UI/Pagination/Pagination";
import ItemPreview from "../components/ItemPreview";
import SearchFilter from "../components/SearchFilter";

import { getNoun } from "../utils/GetNoun";

export default function Favorites({title}) {
  usePage(title, true);

  const [defaultFilters, setDefaultFilters] = useState({
    search: "",
    categories: [],
    brands: [],
    sort: {name: "По возрастанию", value: "ASC"},
    group: {name: "По бренду", value: "brand"},
    price: {
      min: 0,
      max: 1000000
    }
  })

  const [filters, setFilters] = useState(defaultFilters);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [favorites, setFavorites] = useState(localStorage.getItem("favorites") || []);

  const [fetchItems, isItemsLoad] = useFetching(async (page, filters) => {
    const response = await CatalogService.getItemsByIds(page - 1, filters, favorites);
    if (response.data.status) {
      window.scrollTo(0, 0)
      setItems(response.data.response.items);
      setTotalItems(response.data.response.totalItems);
      setTotalPages(response.data.response.totalPagesCount);
      setPage(page);
    }
  })

  useEffect(() => {
    fetchItems(1, filters);
  }, [filters])

  return (
    <section className="content container margin-header">
      <Split>
        <SplitBlock type="fixed" width="350px">
          <SearchFilter filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />
        </SplitBlock>
        <SplitBlock type="grow">
          {
            items.length !== 0 &&
            <div style={{marginBottom: "10px"}}>
              <span>{getNoun(totalItems, "Найден", "Найдено", "Найдено")} {totalItems} {getNoun(totalItems, "товар", "товара", "товаров")}</span>
            </div>
          }
          {
            items.length !== 0
            ? items.map((item, index) => 
                <ItemPreview key={index} item={item} />
              )
            : <Alert className="style--alert-negative">Ничего не найдено</Alert>
          }
          {
            items.length !== 0 &&
            <Pagination currentPage={page} totalPages={totalPages} range={5} changePage={page => fetchItems(page, filters)} />
          }
        </SplitBlock>
      </Split>
    </section>
  );
}